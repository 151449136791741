<template>
    <form-item-wrapper
        :designer="designer"
        :field="field"
        :rules="rules"
        :design-state="designState"
        :parent-widget="parentWidget"
        :parent-list="parentList"
        :index-of-parent-list="indexOfParentList"
        :sub-form-row-index="subFormRowIndex"
        :sub-form-col-index="subFormColIndex"
        :sub-form-row-id="subFormRowId"
    >
        <!-- <el-button
            ref="fieldEditor"
            :type="field.options.type"
            :size="field.options.size"
            :class="[field.options.label === '' ? 'hide-text-span' : '']"
            :plain="field.options.plain"
            :round="field.options.round"
            :circle="field.options.circle"
            :icon="field.options.icon"
            :disabled="field.options.disabled"
            @click.native="dialogVisible = true"
            >{{ field.options.title }}</el-button
        > -->
        <!-- <jf-select
            :value="fieldModel"
            :tabList="tabList"
            :searchable="true"
            :disabled="field.options.disabled"
            :maxNum="field.options.maxNum"
            :show.sync="dialogVisible"
            @input="onConfirm"
            :title="field.options.title"
        ></jf-select> -->
        <!-- :limit="field.options.maxNum" -->
        <admin-selectex
            size="small"
            placeholder="请选择用户"
            title="用户"
            idField="no"
            :limit="field.options.maxNum"
            :disabled="field.options.disabled"
            :value="ids"
            :label="labels"
            :readonly="true"
            @doSubmit="onConfirm"
        />
    </form-item-wrapper>
</template>
  
  <script>
import FormItemWrapper from "./form-item-wrapper";
import emitter from "@/vform/utils/emitter";
import i18n, { translate } from "@/vform/utils/i18n";
import fieldMixin from "@/vform/components/form-designer/form-widget/field-widget/fieldMixin";
import JfSelect from "@/components/jfselect/index";
export default {
    name: "user-select-widget",
    componentName: "FieldWidget", //必须固定为FieldWidget，用于接收父级组件的broadcast事件
    mixins: [emitter, fieldMixin, i18n],
    props: {
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designer: Object,

        designState: {
            type: Boolean,
            default: false,
        },

        subFormRowIndex: {
            /* 子表单组件行索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormColIndex: {
            /* 子表单组件列索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormRowId: {
            /* 子表单组件行Id，唯一id且不可变 */ type: String,
            default: "",
        },
    },
    components: {
        FormItemWrapper,
        JfSelect,
    },
    inject: ["refList", "globalOptionData", "globalModel"],
    data() {
        return {
            fieldModel: null,
            tabList: ["dep"],
            dialogVisible: false,
            rules: [],
            form: {},
        };
    },

    computed: {
        ids() {
            // return this.fieldModel
            //     ? this.fieldModel.map((o) => o.userId).join(",")
            //     : "";
            if (this.fieldModel) {
                let list = this.fieldModel;
                if (typeof this.fieldModel === "string") {
                    list = JSON.parse(this.fieldModel);
                }
                console.log(list,'list')
                return list.map((o) => o.userId).join(",");
            }
            return '';
        },
        labels() {
            if (this.fieldModel) {
                let list = this.fieldModel;
                if (typeof this.fieldModel === "string") {
                    list = JSON.parse(this.fieldModel);
                }
                console.log(list,'list')
                return list
                    .map((o) => o.realname || o.nickName)
                    .join(",");
            }

            return '';
        },
    },
    beforeCreate() {
        /* 这里不能访问方法和属性！！ */
    },

    created() {
        /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
           需要在父组件created中初始化！！ */
        /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
         需要在父组件created中初始化！！ */
        this.registerToRefList();
        this.initEventHandler();
        this.buildFieldRules();
        this.handleOnCreated();
    },

    mounted() {
        this.handleOnMounted();
    },

    beforeDestroy() {
        this.unregisterFromRefList();
    },

    methods: {
        onConfirm(e) {
            let data = e.map((o) => {
                return {
                    userId: o.id ||o.no,
                    userName: o.username,
                    // ignore_datascope:,
                    nickName: o.nickname,
                    realname: o.realname,
                    deptId: o.dept_id,
                    deptName: o.dept ? o.dept.name : "",
                };
            });
            console.log(data);
            this.handleChangeEvent(JSON.stringify(data));
        },
    },
};
</script>
  
  <style lang="scss" scoped>
@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
    width: 100% !important;
}
</style>