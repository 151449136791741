<template>
  <div class="fc-dict">
    <el-select
      v-model="innerValue"
      :disabled="disabled"
      :multiple="multiple"
      :clearable="clearable"
      :placeholder="placeholder"
      :filterable="filterable"
      style="width:100%"
    >
      <el-option
        v-for="(item,index) in dictList"
        :label="item.label"
        :value="item.value" :key="index">
      </el-option>

    </el-select>
  </div>
</template>
<script>
import { listData } from "@/api/core/data/dict/data";
export default {
//   model: {
//     prop: 'value',
//   },
//   ["value", "cmpType", "tag", "tagIcon", "dictType","defaultValue", "placeholder",  "disabled","multiple","clearable","filterable","required","asSummary", "formId", "renderKey", "layout", ]
  props:{
    disabled:{
        type:Boolean,
        default:false
    },
    value:{
        type:[String,Array],
        default:''
    },
    dictType:{
        type:String,
        default:''
    },
    defaultValue:{
        type:[String,Array],
        default:''
    },
    placeholder:{
        type:String,
        default:''
    },
    multiple:{
        type:Boolean,
        default:false
    },
    filterable:{
        type:Boolean,
        default:false
    },
    clearable:{
        type:Boolean,
        default:false
    }
  },
  name: 'fc-dict',
  data () {
    return {
      innerValue: this.defaultValue,
      innerType:this.dictType,
      dictList:[]
    }
  },
  created() {
    this.changeDictList()
  },
  methods: {
    changeDictList() {
      if(this.innerType && this.innerType!='') {
        listData({
          pageNum: 1,
          type: this.innerType,
          pageSize: 9999
        }).then(response => {
            this.dictList = response.data.list;
          }
        );
      }else {
        this.dictList=[]
      }
    }
  },
  watch:{
    innerValue (val) {
      this.$emit('input', val)
    },
    defaultValue (val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    },
    dictType(type) {
      this.innerType =  type
      this.changeDictList(type)
    }
  }
}
</script>
<style lang="stylus" scoped>
</style>
