import * as Formx from "@/api/form/data";
import TabledataSvr from "@/api/bpm/tabledata";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";

export const bpm = {
    data() {
        return {
            needparamfield: [],
            notshowfield: [
                "id",
                "rev",
                "flow_id",
                "report_id",
                "resource_id",
                //"process_status",
                "starter_id",
                "starter_account",
                // "starter_name",
                "dept_id",
                "vars",
                "fields",
                "ext",
                "create_time",
                "update_time",
            ],
        };

    },
    methods: {
        createPdf(ele,filename) {
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;

            html2canvas(ele, {
                allowTaint: true,
                scale: 2,
                dpi: 182, //导出图片清晰度
            }).then(function (canvas) {
                let contentWidth = canvas.width;
                let contentHeight = canvas.height;
                //一页pdf显示html页面生成的canvas高度;
                let pageHeight = (contentWidth / 592.28) * 841.89;
                //未生成pdf的html页面高度
                let leftHeight = contentHeight;
                //页面偏移
                let position = 0;
                let imgWidth = 595.28;
                let imgHeight = (592.28 / contentWidth) * contentHeight;
                //返回图片dataURL，参数：图片格式和清晰度(0-1)
                let pageData = canvas.toDataURL("image/jpeg", 1.0);
                //方向默认竖直，尺寸ponits，格式 a4纸 [595.28,841.89]
                let PDF = new JsPDF("", "pt", "a4");
                //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
                //当内容未超过pdf一页显示的范围，无需分页
                if (leftHeight < pageHeight) {
                    //addImage将图片添加到pdf中
                    //addImage中间两个参数控制x、y边距，
                    //后两个参数控制添加图片的尺寸，此处将页面高度按照a4纸宽高比列进行压缩
                    PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
                } else {
                    while (leftHeight > 0) {
                        PDF.addImage(
                            pageData,
                            "JPEG",
                            0,
                            position,
                            imgWidth,
                            imgHeight
                        );
                        leftHeight -= pageHeight;
                        position -= 841.89;
                        //避免添加空白页
                        if (leftHeight > 0) {
                            //addPage()添加pdf页数
                            PDF.addPage();
                        }
                    }
                }
                //保存名称
                PDF.save(filename||"数据导出.pdf");
            });
        },
        formatdata(list) {
            list.forEach((item) => {
                for (let field in item) {
                    if (
                        this.notshowfield.indexOf(field) == -1 &&
                        this.isjson(item[field])
                    ) {
                        let tjs = JSON.parse(item[field]); //{"dep":[{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}]}
                        // console.log("tjs",tjs)
                        let tvalue = [];
                        if (typeof tjs == "object" && !Array.isArray(tjs)) {
                            for (let k in tjs) {
                                let tav = tjs[k];
                                if (
                                    typeof tav == "object" &&
                                    (k == "dep" || k == "user")
                                ) {
                                    //数组 或对象 [{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}]
                                    for (let a in tav) {
                                        let tba = tav[a];
                                        for (let bf in tba) {
                                            //{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}
                                            if (
                                                bf == "nickName" ||
                                                bf == "deptName"
                                            ) {
                                                tvalue.push(tba[bf]); //取值 安全管理系统
                                            }
                                        }
                                    }
                                }
                            }
                            item[field] = tvalue.join(","); //最终的值
                        }
                    }
                }
            });
            return list;
        },

        isjson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        async getBpmsrvData(fconfig) {
            var param = {
                fieldval: {},
                sortinfo: { id: "desc" },
                pageNum: 1,
                pageSize: fconfig.page || 100,
            };

            let res = await TabledataSvr.tableDataList(param, fconfig.flowid);
            return res.data.rows;
            // .then((response) => {
            //     let datalist = JSON.parse(response.data.rows);
            //     // console.log("datalist", datalist);
            //     this.dataList = this.formatdata(datalist.rows);
            //     this.total = datalist.total;
            //     this.loading = false;
            // })
            // .catch((_) => {
            //     this.loading = false;
            // });
        },
        async getFormsrvData(fconfig) {
            var mid = fconfig.id
            var param = {
                fieldval: {},
                sortinfo: null,
                pageNum: 1,
                pageSize: fconfig.page || 100,
            };

            if (fconfig.staticfilter != null && fconfig.staticfilter != "") {
                try {
                    var f = JSON.parse(fconfig.staticfilter)
                    for (let k in f) {
                        param.fieldval[k] = f[k]

                    }

                } catch (error) {
                    // console.log("error",error)
                }
            }
            let res = await Formx.FormxShowList(mid, param);
            return res.data.list;
        },
        async getFormDataRow(mid, id) {
            let res = await Formx.FormxShowGet(mid, id);
            return res.data.row;
        },
        setFieldformsvrData(from, field) {
            if (field.formsvr && field.formsvr.id) {
                var linkfields = field.linkfields;
                if (linkfields != null && linkfields != "") {
                    var tar = linkfields.split(":");
                    this.needparamfield.push(tar[0]);//此字段需要传参

                }


                // console.log("field", field);
                if (this.needparamfield.length == 0 || this.needparamfield.indexOf(field.vModel) == -1) {

                    var formdatalist = this.getFormsrvData(field.formsvr);
                    formdatalist.then((rs) => {
                        let toption = [];
                        for (let ii in rs) {
                            let onerec = rs[ii];
                            var optionValue = field.formsvr.optionValue
                                ? field.formsvr.optionValue
                                : "id";
                            toption.push({
                                label: onerec[field.formsvr.optionLabel],
                                value: onerec[optionValue],
                                originData: onerec,
                            });
                        }
                        // console.log("toption", toption);
                        // this.$set(this.confGlobal.fields[index],'options',toption)
                        field.renderKey = new Date().getTime();
                        field.options = toption;
                    });
                } else {
                    field.options = []
                }
            }

            //加载一行数据  处理风险等级 lecd
            if (field.optionsdataconfig && field.optionsdataconfig.form) {
                let tar = field.optionsdataconfig.form.split(":");
                let formid = tar[0];
                let dataid = tar[1];
                let formdatarow = this.getFormDataRow(formid, dataid);
                formdatarow.then((data) => {
                    let labelset = field.optionsdataconfig.labelfield;
                    let valueset = field.optionsdataconfig.valuefield;
                    let labelarr = Formx.parseFormDataRowToOption(
                        data,
                        labelset
                    );
                    let valuearr = Formx.parseFormDataRowToOption(
                        data,
                        valueset
                    );
                    let toptiondata = [];
                    for (let ik = 0; ik < labelarr.length; ik++) {
                        toptiondata.push({
                            label: labelarr[ik],
                            value: valuearr[ik],
                            originData: data,
                            cacl: true,
                        });
                    }
                    field.options = toptiondata;
                    //console.log("labelarr valuearr",labelarr,valuearr)
                });
            }

            //处理关联数据表单 引用模式下数据实时同步更新  associated_mode=1 引用模式

            if (from == "task" && field.form_data && field.form_data.id && field.associated_mode === "1") {

                try {
                    setTimeout(() => {
                        //form_type=="1" 表单
                        if (field.defaultValue && Array.isArray(field.defaultValue) && field.defaultValue.length > 0) {
                            //如果有默认值
                            if (field.form_type == "1") {
                                //form_type=="1" 表单
                                var formdatalist = this.getFormsrvData({ id: field.form_data.id });
                                var tlist = this.metchdata(field.defaultValue, formdatalist)

                                field.defaultValue = tlist
                                field.renderKey = new Date().getTime();

                            } else if (field.form_type == "2") {
                                //form_type=="2" 流程 
                                var formdatalist = this.getBpmsrvData({ flowid: field.form_data.id })
                                var tlist = this.metchdata(field.defaultValue, formdatalist)

                                field.defaultValue = tlist
                                field.renderKey = new Date().getTime();

                            }


                        }
                        // field.defaultValue=[{id:90,sbzt:"sdfsdfds"}]

                    }, 0)

                } catch (error) {
                    console.log("error", error)

                }


                // console.log("进入 引用模式 from",field,from )

            }

        },
        metchdata(tarlist, sourcelist) {

            var tlist = []
            sourcelist.then(rs => {
                for (let ii in rs) {
                    for (let ik in tarlist) {
                        let trow = {}
                        if (tarlist[ik].id == rs[ii].id) {
                            var tkeylist = Object.keys(tarlist[ik])
                            //console.log("tkeylist",tkeylist)
                            for (let a in tkeylist) {
                                let tf = tkeylist[a]
                                trow[tf] = rs[ii][tf]
                            }
                            tlist.push(trow)
                        }

                    }
                }
                // console.log("tlist",tlist)

                return tlist


            })

        }
    }
}