<template>
    <div>
        <el-dialog
            :title="config.form_type == 1?'表单数据':'流程数据'"
            :visible.sync="show"
            width="80%"
            :before-close="handleClose"
            :append-to-body="true"
        >
            <div v-if="config.form_type == 1">
                <form-table
                    ref="formTable"
                    :config="config"
                    @change="checkboxChangeEvent"
                ></form-table>
            </div>
            <div v-if="config.form_type == 2">
                <flow-table
                    ref="flowTable"
                    :config="config"
                    @change="checkboxChangeEvent"
                ></flow-table>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="show = false">取 消</el-button>
                <el-button
                    type="primary"
                    :disabled="checkedList.length !== 1"
                    @click="onSubmit"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import TabledataSvr from "@/api/bpm/tabledata";
// import FormTable from "@/components/jfFormTable/formTable";
// import FlowTable from "./components/flow-table";
export default {
    // mixins: [form],
    components: {
        // VFormRender,
        FormTable:() => import('@/components/jfFormTable/formTable'),
        FlowTable:() => import('@/components/jfFlowTable/flowTable')
        // QuestionList: 
    },
    props: {
        config: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            show: false,
            mid: "",
            checkedList: [],
        };
    },

    methods: {
        init() {
            this.show = true;
            this.mid = this.config.form_data.id;

            console.log(this.$refs["formTable"], "config");
            if (this.config.form_type == 1) {
                this.$nextTick(() => {
                    this.$refs["formTable"].init(this.mid);
                });
            } else if (this.config.form_type == 2) {
                this.$nextTick(() => {
                    this.$refs["flowTable"].init(this.mid);
                });
            }
            //
        },

        handleClose() {
            this.show = false;
        },
        checkboxChangeEvent(records) {
            this.checkedList = records;
        },
        onSubmit() {
            this.$emit("change", this.checkedList);
        },
    },
};
</script>

<style>
.vxe-table--tooltip-wrapper {
    z-index: 10000 !important;
}
</style>
<style rel="stylesheet/scss" scoped>
.form-data {
    margin: 0 -2rem;
}
.btn-orange {
    background-color: #ff9648;
    color: #fff;
}

.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background-color: #2196f3;
    color: #fff;
}

::v-deep .scrolldialog ::v-deep .el-dialog__body {
    height: 70vh;
    overflow-y: scroll;
}

.head-container {
    font-size: 14px;
}
.vFormDialog ::v-deep .el-dialog__header {
    display: none;
}
.vFormDialog .btn-submit {
    font-size: 18px;
    border-bottom: #9e9e9e38 solid 1px;
    padding-bottom: 10px;
    margin-top: -7px;
    font-weight: bold;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>