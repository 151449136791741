var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('container-item-wrapper',{attrs:{"widget":_vm.widget}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.widget.options.hidden),expression:"!widget.options.hidden"}],class:['sub-form-container', _vm.disabledClass, _vm.readModeClass]},[_c('el-row',{staticClass:"header-row"},[(_vm.leftActionColumn)?_c('div',{staticClass:"action-header-column"},[_c('span',{staticClass:"action-label"},[_vm._v(_vm._s(_vm.i18nt("render.hint.subFormAction")))]),(!_vm.isReadMode)?_c('el-button',{staticClass:"action-button",attrs:{"disabled":_vm.widgetDisabled || _vm.actionDisabled || _vm.insertDisabled,"round":"","type":"primary","size":"mini","title":_vm.i18nt('render.hint.subFormAddActionHint')},on:{"click":_vm.addSubFormRow}},[_vm._v(" "+_vm._s(_vm.i18nt("render.hint.subFormAddAction"))),_c('i',{staticClass:"el-icon-plus el-icon-right"})]):_vm._e()],1):_vm._e(),(!_vm.leftActionColumn && _vm.widget.options.showRowNumber)?_c('div',{staticClass:"row-no-header-column"},[_c('span',[_vm._v(_vm._s(_vm.i18nt("render.hint.subFormRowNo")))])]):_vm._e(),_vm._l((_vm.widget.widgetList),function(subWidget){return [(!subWidget.options.hidden)?_c('div',{key:subWidget.id + 'thc',staticClass:"field-header-column",class:[
                        _vm.getLabelAlign(_vm.widget, subWidget),
                        !!subWidget.options.required ? 'is-required' : '' ],style:({ width: subWidget.options.columnWidth })},[(!!subWidget.options.labelIconClass)?_c('span',{staticClass:"custom-label"},[(
                                subWidget.options.labelIconPosition ===
                                'front'
                            )?[(!!subWidget.options.labelTooltip)?[_c('el-tooltip',{attrs:{"content":subWidget.options.labelTooltip,"effect":"light"}},[_c('i',{class:subWidget.options.labelIconClass})]),_vm._v(_vm._s(subWidget.options.label))]:[_c('i',{class:subWidget.options.labelIconClass}),_vm._v(_vm._s(subWidget.options.label))]]:(
                                subWidget.options.labelIconPosition ===
                                'rear'
                            )?[(!!subWidget.options.labelTooltip)?[_vm._v(" "+_vm._s(subWidget.options.label)),_c('el-tooltip',{attrs:{"content":subWidget.options.labelTooltip,"effect":"light"}},[_c('i',{class:subWidget.options.labelIconClass})])]:[_vm._v(" "+_vm._s(subWidget.options.label)),_c('i',{class:subWidget.options.labelIconClass})]]:_vm._e()],2):[_c('span',{attrs:{"title":subWidget.options.labelTooltip}},[_vm._v(_vm._s(subWidget.options.label))])]],2):_vm._e()]}),(!_vm.leftActionColumn)?_c('div',{staticClass:"action-header-column"},[_c('span',{staticClass:"action-label"},[_vm._v(_vm._s(_vm.i18nt("render.hint.subFormAction")))]),(!_vm.isReadMode)?_c('el-button',{staticClass:"action-button",attrs:{"disabled":_vm.widgetDisabled || _vm.actionDisabled || _vm.insertDisabled,"round":"","type":"primary","size":"mini","title":_vm.i18nt('render.hint.subFormAddActionHint')},on:{"click":_vm.addSubFormRow}},[_vm._v(" "+_vm._s(_vm.i18nt("render.hint.subFormAddAction"))),_c('i',{staticClass:"el-icon-plus el-icon-right"})]):_vm._e()],1):_vm._e()],2),_vm._l((_vm.rowIdData),function(subFormRowId,sfrIdx){return _c('el-row',{key:subFormRowId,staticClass:"sub-form-row"},[(_vm.leftActionColumn)?_c('div',{staticClass:"sub-form-action-column hide-label",on:{"click":function($event){return _vm.change(sfrIdx)}}},[_c('div',{staticClass:"action-button-column"},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReadMode),expression:"!isReadMode"}],attrs:{"disabled":_vm.widgetDisabled ||
                            _vm.actionDisabled ||
                            _vm.insertDisabled,"circle":"","type":"","icon":"el-icon-circle-plus-outline","title":_vm.i18nt('render.hint.insertSubFormRow')},on:{"click":function($event){return _vm.insertSubFormRow(sfrIdx)}}}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReadMode),expression:"!isReadMode"}],attrs:{"disabled":_vm.widgetDisabled ||
                            _vm.actionDisabled ||
                            _vm.deleteDisabled,"circle":"","type":"","icon":"el-icon-delete","title":_vm.i18nt('render.hint.deleteSubFormRow')},on:{"click":function($event){return _vm.deleteSubFormRow(sfrIdx)}}}),(_vm.widget.options.showRowNumber)?_c('span',{staticClass:"row-number-span"},[_vm._v("#"+_vm._s(sfrIdx + 1))]):_vm._e()],1)]):_vm._e(),(!_vm.leftActionColumn && _vm.widget.options.showRowNumber)?_c('div',{staticClass:"row-no-column",on:{"click":function($event){return _vm.change(sfrIdx)}}},[(_vm.widget.options.showRowNumber)?_c('span',{staticClass:"row-number-span"},[_vm._v("#"+_vm._s(sfrIdx + 1))]):_vm._e()]):_vm._e(),_vm._l((_vm.widget.widgetList),function(subWidget,swIdx){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(!subWidget.options.hidden),expression:"!subWidget.options.hidden"}],key:subWidget.id + 'tc' + subFormRowId,staticClass:"sub-form-table-column hide-label",style:({ width: subWidget.options.columnWidth }),on:{"click":function($event){return _vm.change(sfrIdx)}}},[_c(subWidget.type + '-widget',{key:_vm.fieldSchemaData[sfrIdx][swIdx].id,tag:"component",attrs:{"field":_vm.fieldSchemaData[sfrIdx][swIdx],"parent-list":_vm.widget.widgetList,"index-of-parent-list":swIdx,"parent-widget":_vm.widget,"sub-form-row-id":subFormRowId,"sub-form-row-index":sfrIdx,"sub-form-col-index":swIdx}})],1)]}),(!_vm.leftActionColumn)?_c('div',{staticClass:"sub-form-action-column hide-label"},[_c('div',{staticClass:"action-button-column"},[_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReadMode),expression:"!isReadMode"}],attrs:{"disabled":_vm.widgetDisabled ||
                            _vm.actionDisabled ||
                            _vm.insertDisabled,"circle":"","type":"","icon":"el-icon-circle-plus-outline","title":_vm.i18nt('render.hint.insertSubFormRow')},on:{"click":function($event){return _vm.insertSubFormRow(sfrIdx)}}}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReadMode),expression:"!isReadMode"}],attrs:{"disabled":_vm.widgetDisabled ||
                            _vm.actionDisabled ||
                            _vm.deleteDisabled,"circle":"","type":"","icon":"el-icon-delete","title":_vm.i18nt('render.hint.deleteSubFormRow')},on:{"click":function($event){return _vm.deleteSubFormRow(sfrIdx)}}})],1)]):_vm._e()],2)})],2),_c('el-dialog',{attrs:{"title":_vm.widget.options.form_type == 1 ? '表单数据' : '流程数据',"visible":_vm.show,"width":"80%","before-close":_vm.handleClose,"append-to-body":true},on:{"update:visible":function($event){_vm.show=$event}}},[(_vm.widget.options.form_type == 1)?_c('div',[_c('form-table',{ref:"formTable",attrs:{"config":_vm.widget.options},on:{"change":_vm.checkboxChangeEvent}})],1):_vm._e(),(_vm.widget.options.form_type == 2)?_c('div',[_c('flow-table',{ref:"flowTable",attrs:{"config":_vm.widget.options},on:{"change":_vm.checkboxChangeEvent}})],1):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.show = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","disabled":_vm.checkedList.length !== 1},on:{"click":_vm.onSubmit}},[_vm._v("确 定")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }