<template>
    <div>
        <el-dialog
            title="忽略用户列表"
            width="80%"
            :close-on-click-modal="false"
            :append-to-body="true"
            v-dialogDrag
            class="userDialog"
            :visible.sync="visible"
        >
            <el-container style="height: 500px">
                <el-header
                    style="text-align: left; font-size: 12px; height: 30px"
                >
                    <el-form
                        size="small"
                        :inline="true"
                        ref="queryParams"
                        :model="queryParams"
                        @submit.native.prevent
                    >
                        <el-form-item label="关键词" prop="keyword">
                            <el-input
                                size="small"
                                v-model="queryParams.keyword"
                                placeholder="用户名、姓名或昵称"
                                style="width: 170px"
                                clearable
                                @keyup.enter.native="handleQuery"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="工号" prop="no">
                            <el-input
                                v-model="queryParams.no"
                                placeholder="请输入工号"
                                clearable
                                size="small"
                                style="width: 130px"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                size="mini"
                                @click="handleQuery"
                                >搜索</el-button
                            >
                            <el-button
                                icon="el-icon-refresh"
                                size="mini"
                                @click="resetQuery"
                                >重置</el-button
                            >
                        </el-form-item>
                    </el-form>
                </el-header>
                <el-main class="user-table">
                    <el-table
                        :data="dataList"
                        v-loading="loading"
                        size="mini"
                        ref="table"
                        border
                        :header-row-style="{
                            background: '#f8f8f9 !important',
                            height: '44px',
                        }"
                        stripe
                        @selection-change="selectionChangeHandle"
                        height="calc(100% - 80px)"
                        style="width: 100%"
                    >
                        <el-table-column
                            header-align="center"
                            align="center"
                            v-if="limit <= 1"
                            width="50"
                        >
                            <template slot-scope="scope">
                                <el-radio
                                    :label="scope.row.id"
                                    :value="
                                        dataListAllSelections[0] &&
                                        dataListAllSelections[0].id
                                    "
                                    @change.native="
                                        getTemplateRow(scope.$index, scope.row)
                                    "
                                    ><span></span
                                ></el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column
                            type="selection"
                            header-align="center"
                            v-if="limit > 1"
                            align="center"
                            width="50"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="avatar"
                            header-align="center"
                            align="center"
                            label="头像"
                        >
                            <template slot-scope="scope">
                                <img
                                    v-if="scope.row.avatar"
                                    :src="scope.row.avatar"
                                    style="height: 35px"
                                />
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="username"
                            header-align="center"
                            align="center"
                            sortable="custom"
                            min-width="90"
                            label="用户名"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="nickname"
                            header-align="center"
                            align="center"
                            min-width="90"
                            label="昵称"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="realname"
                            header-align="center"
                            align="center"
                            min-width="90"
                            label="姓名"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="dept.name"
                            header-align="center"
                            align="center"
                            min-width="110"
                            label="所属部门"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="status"
                            header-align="center"
                            align="center"
                            min-width="100"
                            label="状态"
                        >
                            <template slot-scope="scope">
                                <el-tag
                                    v-if="scope.row.status === '1'"
                                    size="small"
                                    type="success"
                                    >正常</el-tag
                                >
                                <el-tag
                                    v-else-if="scope.row.status === '0'"
                                    size="small"
                                    type="danger"
                                    >禁用</el-tag
                                >
                                <el-tag
                                    v-else-if="scope.row.status === '2'"
                                    size="small"
                                    type="warning"
                                    >未验证</el-tag
                                >
                            </template>
                        </el-table-column>
                    </el-table>
                    <pagination
                        v-show="total > 0"
                        :total="total"
                        :page.sync="queryParams.pageNum"
                        :limit.sync="queryParams.pageSize"
                        :pageSizes="pageSizes"
                        @pagination="PaginationChangehandle"
                    />
                </el-main>
            </el-container>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doSubmit()">确 定</el-button>
                <el-button @click="visible = false">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { treeselect } from "@/api/core/system/dept";
import { listUser } from "@/api/core/system/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
    components: {
        Treeselect,
    },
    name: "user-list",
    data() {
        return {
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 50,
                keyword: undefined,
                no: undefined,
                type: this.type,
                status: undefined,
                dept_id: undefined,
                with_sub_dept_admin: true,
                ignore_datascope: this.ignoreDatascope,
            },
            deptName: "",
            dataListAllSelections: [], // 所有选中的数据包含跨页数据
            dataListSelections: [],
            idKey: "id", // 标识列表数据中每一行的唯一键的名称(需要按自己的数据改一下)
            dataList: [],
            deptOptions: [],
            defaultProps: {
                children: "children",
                label: "name",
            },
            // 日期范围
            dateRange: [],
            // 状态数据字典
            statusOptions: [],
            // 性别状态字典
            genderOptions: [],
            total: 0,
            loading: false,
            visible: false,
            initData:{}
        };
    },
    props: {
        selectData: {
            type: Array,
            default: () => {
                return [];
            },
        },
        title: {
            type: String,
            default: () => {
                return "用户选择";
            },
        },
        ignoreDatascope: {
            type: String,
            default: () => {
                return "0";
            },
        },
        limit: {
            type: Number,
            default: 999999,
        },
        pageSizes: {
            type: Array,
            default() {
                return [10, 20, 30, 50, 100, 1500];
            },
        },
        type: {
            type: String,
            default: () => {
                return undefined;
            },
        },
    },
    watch: {
        deptName(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        init(data, tabKey,array) {
            this.queryParams={
                pageNum: 1,
                pageSize: 50,
                keyword: undefined,
                no: undefined,
                type: this.type,
                status: undefined,
                dept_id: undefined,
                with_sub_dept_admin: true,
                ignore_datascope: this.ignoreDatascope,
            }
            if(tabKey ==='dep'){
              this.queryParams.dept_id =data.deptId==100?'0':data.deptId
              this.initData.dept_id =data.deptId==100?'0':data.deptId
            }
            if(tabKey ==='position'){
              this.queryParams.post_ids =data.postId
              this.initData.post_ids =data.postId
            }
            if(tabKey ==='director'){
              this.queryParams.leader_nos =data.directorId
              this.initData.leader_nos =data.directorId
            }
            if(tabKey ==='role'){
              this.queryParams.role_ids =data.roleId
              this.initData.role_ids =data.roleId
            }
            if(array.length){

                this.dataListAllSelections = array
            }
            this.visible = true;
            this.handleQuery()
            // this.$nextTick(() => {
            //     console.log(this.selectData);
            //     this.dataListAllSelections = JSON.parse(
            //         JSON.stringify(this.selectData)
            //     );

            //     this.getTreeselect();
            //     this.resetQuery();
            // });
        },
        getTemplateRow(index, row) {
            // 获取选中数据
            this.dataListAllSelections = [row];
            // this.dataListSelections = [row]
            // this.$nextTick(() => {
            // 	this.changePageCoreRecordData()
            // })
        },
        // 设置选中的方法
        setSelectRow() {
            if (
                !this.dataListAllSelections ||
                this.dataListAllSelections.length <= 0
            ) {
                this.$refs.table.clearSelection();
                return;
            }
            // 标识当前行的唯一键的名称
            let idKey = this.idKey;
            let selectAllIds = [];
            this.dataListAllSelections.forEach((row) => {
                selectAllIds.push(row[idKey]);
            });

            this.$refs.table.clearSelection();
            for (let i = 0; i < this.dataList.length; i++) {
                if (selectAllIds.indexOf(this.dataList[i][idKey]) >= 0) {
                    // 设置选中，记住table组件需要使用ref="table"

                    this.$refs.table.toggleRowSelection(this.dataList[i], true);
                }
            }
        },
        // 记忆选择核心方法
        changePageCoreRecordData() {
            // 标识当前行的唯一键的名称
            let idKey = this.idKey;
            console.log(this.idKey);
            let that = this;
            // 如果总记忆中还没有选择的数据，那么就直接取当前页选中的数据，不需要后面一系列计算
            if (this.dataListAllSelections.length <= 0) {
                this.dataListSelections.forEach((row) => {
                    that.dataListAllSelections.push(row);
                });
                return;
            }
            // 总选择里面的key集合
            let selectAllIds = [];
            this.dataListAllSelections.forEach((row) => {
                selectAllIds.push(row[idKey]);
            });
            let selectIds = [];
            // 获取当前页选中的id
            this.dataListSelections.forEach((row) => {
                selectIds.push(row[idKey]);
                // 如果总选择里面不包含当前页选中的数据，那么就加入到总选择集合里
                if (selectAllIds.indexOf(row[idKey]) < 0) {
                    that.dataListAllSelections.push(row);
                }
            });
            let noSelectIds = [];
            // 得到当前页没有选中的id
            this.dataList.forEach((row) => {
                if (selectIds.indexOf(row[idKey]) === -1) {
                    noSelectIds.push(row[idKey]);
                }
            });

            noSelectIds.forEach((id) => {
                if (selectAllIds.indexOf(id) >= 0) {
                    for (
                        let i = 0;
                        i < that.dataListAllSelections.length;
                        i++
                    ) {
                        if (that.dataListAllSelections[i][idKey] === id) {
                            // 如果总选择中有未被选中的，那么就删除这条
                            that.dataListAllSelections.splice(i, 1);
                            break;
                        }
                    }
                }
            });
        },
        // 得到选中的所有数据
        getAllSelectionData() {
            // 再执行一次记忆勾选数据匹配，目的是为了在当前页操作勾选后直接获取选中数据
            this.changePageCoreRecordData();
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.name.indexOf(value) !== -1;
        },
        del(tag) {
            this.dataListAllSelections.splice(
                this.dataListAllSelections.indexOf(tag),
                1
            );
            this.$nextTick(() => {
                this.setSelectRow();
            });
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.queryParams.dept_id = 0;
            this.dateRange = [];
            this.resetForm("queryForm");
            this.queryParams = Object.assign(this.queryParams,this.initData)
            this.handleQuery();
        },

        /** 查询用户列表 */
        getList() {
            this.loading = true;
            listUser(this.addDateRange(this.queryParams, this.dateRange)).then(
                (response) => {
                    let list = [];
                    if (response.data.list) {
                        response.data.list.forEach((item) => {
                            item.status = "" + item.status;
                            list.push(item);
                        });
                    }
                    this.dataList = list;
                    this.total = response.data.total;
                    this.statusOptions = response.data.statusOptions;
                    this.genderOptions = response.data.genderOptions;
                    this.loading = false;
                    this.$nextTick(() => {
                        this.setSelectRow();
                    });
                }
            );
        },
        // 获取数据列表
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        getTreeselect() {
            treeselect().then((response) => {
                this.deptOptions = response.data;
            });
        },
        //
        PaginationChangehandle() {
            this.getList();
            if (this.limit > 1) {
                this.$nextTick(() => {
                    this.changePageCoreRecordData();
                });
            }
        },
        // 多选
        selectionChangeHandle(val) {
            this.dataListSelections = val;

            this.$nextTick(() => {
                this.changePageCoreRecordData();
            });
        },
        handleNodeClick(data) {
            this.queryParams.dept_id = data.id;
            this.getList();
        },
        doSubmit() {
            if (this.limit < this.dataListAllSelections.length) {
                this.$message.error(`你最多只能选择${this.limit}个用户`);
                return;
            }
            this.visible = false;
            this.$emit("doSubmit", this.dataListAllSelections);
        },
    },
};
</script>
<style lang="scss">
.dept {
    height: 100%;
    width: 100%;

    .el-card__header {
        padding: 10px;
    }

    .el-card__body {
        padding: 10px;
        max-height: 520px;
        overflow: auto;
    }
}

.userDialog {
    .el-dialog__body {
        padding: 10px 0px 0px 10px;
        color: #606266;
        font-size: 14px;
        word-break: break-all;
    }

    .el-main {
        padding: 20px 20px 5px 20px;

        .el-pagination {
            margin-top: 5px;
        }
    }
}

.user-table .el-table th.el-table__cell {
    background-color: transparent !important;
}
</style>