var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fc-table-box",class:[_vm.config.type]},[_c('el-table',_vm._b({staticClass:"fc-table",attrs:{"data":_vm.tableFormData,"border":"","row-class-name":_vm.tableRowClassName,"highlight-current-row":"","show-summary":_vm.config['show-summary'],"summary-method":_vm.getTableSummaries},on:{"cell-click":_vm.focusInput,"row-click":_vm.handleRowClick}},'el-table',_vm.config.tableConf || {},false),[_c('el-table-column',{attrs:{"width":"50","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"row-action"},[_c('span',{class:!_vm.config['disabled'] ? 'index' : ''},[_vm._v(" "+_vm._s(scope.$index + 1)+" ")]),(!_vm.config['disabled'])?_c('el-popconfirm',{attrs:{"title":"确定删除该行数据吗？"},on:{"confirm":function($event){return _vm.removeRow(scope.$index)}}},[_c('i',{staticClass:"el-icon-delete delete-btn",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]}}])}),_vm._l((_vm.tableData),function(head,cindex){return _c('el-table-column',{key:head.formId,attrs:{"min-width":head['min-width'],"prop":head.vModel},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                        [
                            'el-select',
                            'el-checkbox-group',
                            'el-radio-group' ].includes(head.tag)
                    )?[_c('el-select',{attrs:{"disabled":_vm.config.type == 'form' ? true : false,"placeholder":"请选择","multiple":head.tag === 'el-checkbox-group' ||
                            _vm.getConfById(head.formId).multiple},on:{"change":function($event){return _vm.onFormDataChange(
                                scope.$index,
                                cindex,
                                'el-select'
                            )}},model:{value:(_vm.tableFormData[scope.$index][cindex].value),callback:function ($$v) {_vm.$set(_vm.tableFormData[scope.$index][cindex], "value", $$v)},expression:"tableFormData[scope.$index][cindex].value"}},_vm._l((head.options),function(opt,oindex){return _c('el-option',{key:oindex,attrs:{"label":opt.label,"value":opt.value}})}),1)]:(head.tag === 'el-upload')?[_c('el-upload',_vm._b({attrs:{"headers":_vm.headers,"on-success":function (res) { return _vm.onUploadSuccess(
                                    res,
                                    _vm.tableFormData[scope.$index][cindex]
                                ); }},nativeOn:{"mouseleave":function($event){return _vm.hideUploadList.apply(null, arguments)},"mouseenter":function($event){return _vm.showUploadList.apply(null, arguments)}}},'el-upload',_vm.getConfById(head.formId),false),[_c('span',{attrs:{"slot":"default"},slot:"default"},[_vm._v(" 已上传 "+_vm._s(_vm.tableFormData[scope.$index][cindex].value .length)+" ")])])]:(head.tag === 'fc-dict')?[_c('dict',{attrs:{"defaultValue":_vm.tableFormData[scope.$index][cindex].value,"multiple":head.tag === 'el-checkbox-group' ||
                            _vm.getConfById(head.formId).multiple,"disabled":_vm.config.type == 'form' ? true : false,"dictType":head.dictType}})]:_c(head.tag,_vm._b({tag:"component",attrs:{"rowIndex":scope.$index,"disabled":_vm.config.type == 'form' ? true : false,"formData":_vm.formData},on:{"change":function($event){return _vm.onFormDataChange(scope.$index, cindex, head.tag)}},model:{value:(_vm.tableFormData[scope.$index][cindex].value),callback:function ($$v) {_vm.$set(_vm.tableFormData[scope.$index][cindex], "value", $$v)},expression:"tableFormData[scope.$index][cindex].value"}},'component',_vm.getConfById(head.formId),false)),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tableFormData[scope.$index][cindex].valid),expression:"!tableFormData[scope.$index][cindex].valid"}],staticClass:"error-tip"},[_vm._v(" 不能为空 ")])]}}],null,true)},[_c('template',{slot:"header"},[(head.required)?_c('span',{staticStyle:{"color":"#f56c6c"}},[_vm._v("*")]):_vm._e(),_vm._v(" "+_vm._s(head["label"])+" ")])],2)}),(!_vm.config.disabled)?_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.handleClick(scope.row, scope.$index)}}},[_vm._v("选择")])]}}],null,false,3017894091)}):_vm._e()],2),(_vm.config.data_num == 2)?_c('div',{staticClass:"actions"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.addRow}},[_c('i',{staticClass:"el-icon-plus"}),_vm._v(" "+_vm._s(_vm.config.actionText)+" ")])],1):_vm._e(),_c('form-list-dialog',{ref:"FormListDialog",attrs:{"config":_vm.config},on:{"change":_vm.checkboxChangeEvent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }