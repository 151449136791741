import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'
class TableData {
    //获取数据表信息
    tableInfo(flowid){
        return request({
            url: '/bpm/tableinfo/' + flowid,
            method: 'get'
        })
    }

    Laobaoqrcodedetail(data){
        return request({
            url: '/bpm/laobao/qrcodedetail' ,
            method: 'get',
            params:data
        })
    }

    tableDataList(query,flowid) {
        var data = {
            page: query.pageNum,
            psize: query.pageSize,
            qstr: {},
            flowid: flowid+""
        }

        var tjson = ReFormatQueryStr(query)

        data.qstr = JSON.stringify(tjson)

        return request({
            url: '/bpm/tabledata/list/'+ flowid,
            method: 'post',
            data: data
        })
    }

}



const instance = new TableData();
export default instance;