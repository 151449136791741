import PROVINCE from "./province.js";

export const formConf = {
  formRef: "elForm",
  size: "small",
  labelPosition: "top",
  labelWidth: 100,
  gutter: 15,
  disabled: false,
  span: 24,
  formBtns: true,
  show: false,
  child_process:{
    name:'',
    id:''
  },
  is_child_process:false,
  child_operate_name:'',
// parent_process:{
//     name:'',
//     id:''
//   },
  //表单关联配置
  formsvr: {
    id: undefined,
    fieldsmatch: [
      {
        field: "", //流程表单字段
        foreignfield: "", //关联表单字段
        rw: false, //是否反写
      },
    ],
  },
};

export const inputComponents = [
  {
    label: "单行输入框",
    tag: "el-input",
    tagIcon: "input",
    showpos: [],
    type: "input",
    placeholder: "请输入",
    defaultValue: undefined,
    span: 24,
    labelWidth: null,
    style: { width: "100%" },
    clearable: true,
    prepend: "",
    append: "",
    "prefix-icon": "",
    "suffix-icon": "",
    maxlength: 500,
    "show-word-limit": true,
    readonly: false,
    disabled: false,
    required: false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    asOpinion: false,
    show: false, //为true前后端都会隐藏，hidden会直接影响到原生
    isApphide: false, //为true手机端隐藏
  },
  {
    label: "分割线",
    tag: "el-divider",
    tagIcon: "divider",
    type: "divider",
    content: "请输入文字",
    // placeholder: '请输入',
    defaultValue: undefined,
    span: 24,
    labelWidth: null,
    style: { width: "100%" },
    contentPosition: "center",
    show: false,
    isApphide: false,
    // clearable: true,
    // prepend: '',
    // append: '',
    // 'prefix-icon': '',
    // 'suffix-icon': '',
    // maxlength: 500,
    // 'show-word-limit': true,
    // readonly: false,
    // disabled: false,
    // required: false,
    // regList: [],
    // changeTag: true,
    // proCondition: false,
    // asSummary: false,
    // asOpinion:false,
  },
  {
    label: "多行输入框",
    tag: "el-input",
    tagIcon: "textarea",
    type: "textarea",
    showpos: [],
    placeholder: "请输入",
    defaultValue: undefined,
    span: 24,
    labelWidth: null,
    autosize: {
      minRows: 4,
      maxRows: 4,
    },
    style: { width: "100%" },
    maxlength: 8000,
    "show-word-limit": true,
    readonly: false,
    disabled: false,
    required: false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    asOpinion: false,
    show: false,
    isApphide: false,
  },
  {
    label: "二维码",
    tag: "qr-code",
    tagIcon: "qr-code",
    type: "qr-code",
    showpos: [],
    placeholder: "请输入",
    defaultValue: "http://scwap.connectbilian.com/#/",
    // defaultValue:'',
    // span: 24,
    // labelWidth: null,
    // autosize: {
    //   minRows: 4,
    //   maxRows: 4,
    // },
    // style: { width: "100%" },
    // maxlength: 8000,
    // "show-word-limit": true,
    // readonly: false,
    // disabled: false,
    // required: false,
    qrCodeSize: 148,
    bgColor: "#ffffff",
    // f_flowId: "",
    // flowId: "",
    // value:'',
    is_taskId: true,
    is_resourceId: true,
    is_flowInstId: true,

    // regList: [],
    // changeTag: true,
    // proCondition: false,
    // asSummary: false,
    // asOpinion: false,
    show: false,
    isApphide: false,
  },
  {
    label: "密码",
    tag: "el-input",
    tagIcon: "password",
    type: "password",
    showpos: [],
    placeholder: "请输入",
    defaultValue: undefined,
    span: 24,
    "show-password": true,
    labelWidth: null,
    style: { width: "100%" },
    clearable: true,
    prepend: "",
    append: "",
    "prefix-icon": "",
    "suffix-icon": "",
    maxlength: null,
    "show-word-limit": false,
    readonly: false,
    disabled: false,
    required: false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "数字输入框",
    tag: "el-input-number",
    tagIcon: "number",
    type: "number",
    showpos: [],
    placeholder: "",
    defaultValue: undefined,
    style: { width: null },
    span: 24,
    labelWidth: null,
    min: undefined,
    max: undefined,
    step: undefined,
    "step-strictly": false,
    precision: undefined,
    "controls-position": "right",
    disabled: false,
    required: false,
    regList: [],
    changeTag: true,
    proCondition: true,
    show: false,
    isApphide: false,
  },
  {
    label: "金额",
    tag: "fc-amount",
    tagIcon: "amount",
    showpos: [],
    type: "amount",
    placeholder: "请输入",
    defaultValue: undefined,
    style: { width: null },
    span: 24,
    labelWidth: null,
    min: undefined,
    max: undefined,
    step: undefined,
    "step-strictly": false,
    precision: undefined,
    "controls-position": "right",
    disabled: false,
    required: false,
    regList: [],
    changeTag: true,
    proCondition: true,
    showChinese: true,
    show: false,
    isApphide: false,
  },
];

export const selectComponents = [
  {
    label: "下拉选择",
    tag: "el-select",
    tagIcon: "select",
    showpos: [],
    type: "select",
    placeholder: "请选择",
    style: { width: "100%" },
    defaultValue: undefined,
    span: 24,
    labelWidth: null,
    clearable: true,
    disabled: false,
    required: false,
    filterable: false,
    multiple: false,
    options: [
      {
        label: "选项一",
        value: "选项一",
      },
      {
        label: "选项二",
        value: "选项二",
      },
    ],
    regList: [],
    changeTag: true,
    proCondition: true,
    //关联表单引擎
    formsvr: {
      id: undefined,
      optionLabel: undefined,
      optionValue: "id",
      staticfilter: "", //静态条件
    },
    show: false,
    isApphide: false,
    linkfields: "", //联动字段 用于动态设置下拉数据
    optionsdataconfig: {
      type: "formrow", //表单行数据
      form: "", //表单 表单id:数据id  234234:1
      labelfield: "", //标签取值配置subform39415.[v_E]+分+-+subform39415.[input29579]
      valuefield: "", //数值取值配置subform39415.[v_E]
    },
  },
  {
    label: "级联选择",
    tag: "el-cascader",
    tagIcon: "cascader",
    showpos: [],
    type: "cascader",
    placeholder: "请选择",
    defaultValue: [],
    span: 24,
    labelWidth: null,
    style: { width: "100%" },
    props: {
      props: {
        multiple: false,
      },
    },
    "show-all-levels": true,
    disabled: false,
    clearable: true,
    filterable: false,
    required: false,
    options: [
      {
        id: 1,
        value: "选项1",
        label: "选项1",
        children: [
          {
            id: 2,
            value: "选项1-1",
            label: "选项1-1",
          },
        ],
      },
    ],
    dataType: "static",
    labelKey: "label",
    valueKey: "value",
    childrenKey: "children",
    separator: "/",
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "省市区",
    tag: "el-cascader",
    showpos: [],
    tagIcon: "area",
    type: "area",
    placeholder: "请选择",
    defaultValue: [],
    span: 24,
    labelWidth: null,
    style: { width: "100%" },
    props: {
      props: {
        multiple: false,
      },
    },
    "show-all-levels": true,
    disabled: false,
    clearable: true,
    filterable: false,
    required: false,
    options: PROVINCE,
    dataType: "static",
    labelKey: "label",
    valueKey: "value",
    childrenKey: "children",
    separator: "/",
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "单选框组",
    tag: "el-radio-group",
    tagIcon: "radio",
    type: "radio",
    showpos: [],
    defaultValue: undefined,
    span: 24,
    labelWidth: null,
    style: {},
    optionType: "default",
    border: false,
    size: "medium",
    disabled: false,
    required: false,
    options: [
      {
        label: "选项一",
        value: "选项一",
      },
      {
        label: "选项二",
        value: "选项二",
      },
    ],
    regList: [],
    changeTag: true,
    proCondition: true,
    show: false,
    isApphide: false,
  },
  {
    label: "多选框组",
    tag: "el-checkbox-group",
    tagIcon: "checkbox",
    type: "checkbox",
    showpos: [],
    defaultValue: [],
    span: 24,
    labelWidth: null,
    style: {},
    optionType: "default",
    border: false,
    size: "medium",
    disabled: false,
    required: false,
    options: [
      {
        label: "选项一",
        value: "选项一",
      },
      {
        label: "选项二",
        value: "选项二",
      },
    ],
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "开关",
    tag: "el-switch",
    tagIcon: "switch",
    showpos: [],
    type: "switch",
    defaultValue: false,
    span: 24,
    labelWidth: null,
    style: {},
    disabled: false,
    required: false,
    "active-text": "",
    "inactive-text": "",
    "active-color": null,
    "inactive-color": null,
    "active-value": true,
    "inactive-value": false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "滑块",
    tag: "el-slider",
    tagIcon: "slider",
    type: "slider",
    showpos: [],
    defaultValue: null,
    span: 24,
    labelWidth: null,
    disabled: false,
    required: false,
    min: 0,
    max: 100,
    step: 1,
    "show-stops": false,
    range: false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },

  {
    label: "人员选择",
    tag: "fc-org-select",
    tagIcon: "dept",
    showpos: [],
    type: "user",
    defaultValue: null,
    tabList: ["user"],
    buttonType: "button",
    title: "人员",
    searchable: true,
    maxNum: 1,
    tagConfig: {
      type: "info",
      closable: true,
      "disable-transitions": false,
      hit: false,
      color: undefined,
      size: "small",
      effect: "light",
    },
    style: { width: "100%" },
    span: 24,
    labelWidth: null,
    disabled: false,
    required: true,
    regList: [],
    changeTag: true,
    proCondition: true,
    asSummary: false,
    show: false,
    isApphide: false,
    // isStudent:false,//信科档案是否调用学生列表
    is_ignore_datascope: false, //是否只显示本单位部门
  },
  {
    label: "部门选择",
    tag: "fc-org-select",
    tagIcon: "dept",
    type: "dept",
    showpos: [],
    defaultValue: null,
    tabList: ["dep"],
    buttonType: "button",
    title: "部门",
    searchable: true,
    maxNum: 1,
    tagConfig: {
      type: "info",
      closable: true,
      "disable-transitions": false,
      hit: false,
      color: undefined,
      size: "small",
      effect: "light",
    },
    style: { width: "100%" },
    span: 24,
    labelWidth: null,
    disabled: false,
    required: true,
    regList: [],
    changeTag: true,
    proCondition: true,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "岗位选择",
    tag: "post-select",
    tagIcon: "dept",
    showpos: [],
    type: "post",
    defaultValue: null,
    tabList: ["post"],
    buttonType: "button",
    title: "岗位",
    searchable: true,
    maxNum: 1,
    tagConfig: {
      type: "info",
      closable: true,
      "disable-transitions": false,
      hit: false,
      color: undefined,
      size: "small",
      effect: "light",
    },
    style: { width: "100%" },
    span: 24,
    labelWidth: null,
    disabled: false,
    required: true,
    regList: [],
    changeTag: true,
    proCondition: true,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    // el-time-picker
    label: "时间选择",
    tag: "el-time-picker",
    showpos: [],
    tagIcon: "time",
    type: "time",
    placeholder: "请选择",
    defaultValue: null,
    span: 24,
    labelWidth: null,
    style: { width: "100%" },
    disabled: false,
    clearable: true,
    required: false,
    /*'picker-options': {
      selectableRange: '00:00:00-23:59:59'
    },*/
    format: "HH:mm",
    "value-format": "HH:mm",
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "时间范围",
    tag: "fc-time-duration",
    showDuration: true,
    showpos: [],
    tagIcon: "time-range",
    type: "timerange",
    defaultValue: null,
    span: 24,
    labelWidth: null,
    style: { width: "100%" },
    disabled: false,
    clearable: true,
    required: false,
    "is-range": true,
    "range-separator": "至",
    "start-placeholder": "开始时间",
    "end-placeholder": "结束时间",
    format: "HH:mm",
    "value-format": "HH:mm",
    regList: [],
    changeTag: true,
    proCondition: true,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "日期选择",
    tag: "el-date-picker",
    showpos: [],
    tagIcon: "date",
    type: "date",
    placeholder: "请选择",
    defaultValue: null,
    span: 24,
    labelWidth: null,
    style: { width: "100%" },
    disabled: false,
    clearable: true,
    required: false,
    format: "yyyy-MM-dd",
    "value-format": "yyyy-MM-dd",
    dateRange: undefined,
    readonly: false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "日期范围",
    tag: "fc-date-duration",
    showpos: [],
    showDuration: true,
    tagIcon: "date-range",
    type: "daterange",
    defaultValue: null,
    span: 24,
    labelWidth: null,
    style: { width: "100%" },
    "range-separator": "至",
    "start-placeholder": "开始日期",
    "end-placeholder": "结束日期",
    disabled: false,
    clearable: true,
    required: false,
    dateRange: undefined,
    format: "yyyy-MM-dd",
    "value-format": "yyyy-MM-dd",
    readonly: false,
    regList: [],
    changeTag: true,
    proCondition: true,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "评分",
    tag: "el-rate",
    tagIcon: "rate",
    showpos: [],
    type: "rate",
    defaultValue: 0,
    span: 24,
    labelWidth: null,
    style: {},
    max: 5,
    "allow-half": false,
    "show-text": false,
    "show-score": false,
    disabled: false,
    required: false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "颜色选择",
    tag: "el-color-picker",
    showpos: [],
    tagIcon: "color",
    type: "color",
    defaultValue: null,
    labelWidth: null,
    "show-alpha": false,
    "color-format": "",
    disabled: false,
    required: false,
    size: "medium",
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "附件",
    tag: "el-upload",
    tagIcon: "upload",
    type: "upload",
    action: "/file/upload?category=flow&public=2",
    defaultValue: [],
    labelWidth: null,
    disabled: false,
    required: false,
    accept: "",
    name: "file",
    "auto-upload": true,
    showTip: false,
    buttonText: "点击上传附件",
    fileSize: 20,
    limit: 1,
    sizeUnit: "MB",
    "list-type": "text",
    multiple: false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "图片",
    tag: "el-upload",
    tagIcon: "upload",
    type: "image",
    action: "/file/upload?category=flow&public=2",
    defaultValue: [],
    labelWidth: null,
    disabled: false,
    required: false,
    accept: "image/*",
    name: "file",
    "auto-upload": true,
    showTip: false,
    buttonText: "点击上传图片",
    fileSize: 20,
    limit: 1,
    sizeUnit: "MB",
    "list-type": "picture-card",
    multiple: false,
    regList: [],
    changeTag: true,
    proCondition: false,
    asSummary: false,
    show: false,
    isApphide: false,
    isReadPapers: false,
  },
  {
    label: "计算公式",
    type: "calculate",
    tag: "fc-calculate",
    showChinese: true,
    tagIcon: "calculate",
    defaultValue: null,
    labelWidth: null,
    disabled: false,
    required: false,
    changeTag: true,
    expression: [],
    precision: undefined,
    proCondition: true,
    asSummary: false,
    show: false,
    isApphide: false,
  },
  {
    label: "文字",
    type: "text",
    tag: "fc-text",
    hideLabel: false,
    textColor: "#868686",
    defaultValue: "这是一段描述文字",
    tagIcon: "fa fa-font",
    labelWidth: null,
    show: false,
    isApphide: false,
  },
  {
    label: "HTML",
    type: "html",
    tag: "fc-html",
    hideLabel: false,
    defaultValue: '<div style="color:red">HTML5 内容</div>',
    tagIcon: "fa fa-internet-explorer",
    labelWidth: null,
    show: false,
    isApphide: false,
  },
  {
    label: "编辑器",
    tagIcon: "el-icon-document",
    type: "editor",
    tag: "fc-editor",
    hideLabel: false,
    defaultValue: "",
    disabled: false,
    labelWidth: null,
    show: false,
    isApphide: false,
  },
  {
    label: "字典",
    type: "dict",
    tag: "fc-dict",
    multiple: false,
    filterable: false,
    clearable: true,
    placeholder: "请选择",
    style: { width: "100%" },
    dictType: "",
    defaultValue: undefined,
    required: false,
    disabled: false,
    tagIcon: "dict",
    labelWidth: null,
    show: false,
    isApphide: false,
  },
  {
    label: "签名",
    type: "sign",
    tag: "fc-sign",
    multiple: false,
    filterable: false,
    clearable: true,
    placeholder: "请选择",
    style: { width: "100%" },
    defaultValue: undefined,
    required: false,
    disabled: false,
    tagIcon: "el-icon-edit",
    labelWidth: null,
    show: false,
    isApphide: false,
  },
];
// 容器组件
export const layoutComponents = [
  {
    layout: "rowFormItem",
    rowType: "layout",
    tagIcon: "row",
    type: "default",
    justify: "start",
    align: "top",
    label: "布局容器",
    layoutTree: true,
    children: [],
    showDivider: true,
    show: false,
    isApphide: false,
  },
  {
    layout: "rowFormItem",
    rowType: "table",
    tagIcon: "table",
    tag: "fc-form-input-table",
    type: "form", // list
    justify: "start",
    align: "top",
    label: "关联表单",
    layoutTree: false,
    children: [],
    actionText: "添加",
    tableConf: {},
    "show-summary": true,
    defaultValue: [],
    show: false,
    isApphide: false,
    //关联表单引擎
    form_data: {
      id: undefined,
      name:''
    },
    api: "",
    form_type: "1", //表单类型
    field_list: [], //表单显示列表
    data_num: "1", //展示数据条目
    filters: [], //过滤条件
    associated_mode: "1", //关联模式
    initiator:{is_default:false,field:''},
  },
  {
    layout: "rowFormItem",
    rowType: "table",
    tagIcon: "table",
    tag: "fc-input-table",
    type: "table", // list
    justify: "start",
    align: "top",
    label: "表格/明细",
    layoutTree: false,
    children: [],
    actionText: "添加",
    tableConf: {},
    "show-summary": true,
    defaultValue: [],
    show: false,
    isApphide: false,
  },
];

export const commonComponents = [
  ...inputComponents,
  ...selectComponents,
  ...layoutComponents,
].map((t) => Object.assign({ cmpType: "common" }, t));

const getConfigByTag = (targetList, tag) =>
  targetList.find((t) => t.tag === tag);
const copyConfigAsCustom = (rowConf, childrenConf) => {
  const clone = (target, conf = {}) => {
    const template = JSON.parse(JSON.stringify(target));
    return Object.assign({}, template, { cmpType: "custom" }, conf);
  };
  const defaultRow = clone(layoutComponents[0], rowConf);
  defaultRow.children = childrenConf.map((t) => clone(t.target, t.config));
  return defaultRow;
};
// 定制组件添加cmpType = custom
export const customMadeComponents = [
  copyConfigAsCustom({ label: "请假套件", explain: null }, [
    {
      target: getConfigByTag(selectComponents, "el-select"),
      config: {
        label: "请假类型",
        required: true,
        options: [
          {
            label: "病假",
            value: "病假",
          },
          {
            label: "事假",
            value: "事假",
          },
          {
            label: "婚假",
            value: "婚假",
          },
          {
            label: "丧假",
            value: "丧假",
          },
          {
            label: "产假",
            value: "产假",
          },
          {
            label: "年假",
            value: "年假",
          },
        ],
      },
    },
    {
      target: getConfigByTag(selectComponents, "fc-date-duration"),
      config: {
        required: true,
        label: "日期范围",
        showDuration: true,
      },
    },
  ]),
  copyConfigAsCustom({ label: "出差套件", explain: null }, [
    {
      target: getConfigByTag(inputComponents, "el-input"),
      config: {
        label: "出差事由",
        required: true,
      },
    },
    {
      target: getConfigByTag(selectComponents, "el-select"),
      config: {
        label: "交通工具",
        required: true,
        options: [
          {
            label: "火车",
            value: "火车",
          },
          {
            label: "飞机",
            value: "飞机",
          },
          {
            label: "汽车",
            value: "汽车",
          },
          {
            label: "其他",
            value: "其他",
          },
        ],
      },
    },
    {
      target: getConfigByTag(selectComponents, "el-select"),
      config: {
        label: "单程往返",
        required: true,
        options: [
          {
            label: "单程",
            value: "单程",
          },
          {
            label: "往返",
            value: "往返",
          },
        ],
      },
    },
    {
      target: getConfigByTag(inputComponents, "el-input"),
      config: {
        label: "出发城市",
        required: true,
      },
    },
    {
      target: getConfigByTag(inputComponents, "el-input"),
      config: {
        label: "目的城市",
        required: true,
      },
    },
    {
      target: getConfigByTag(selectComponents, "fc-date-duration"),
      config: {
        label: "日期范围",
        tag: "fc-date-duration",
        showDuration: true,
        required: true,
      },
    },
    {
      target: getConfigByTag(inputComponents, "el-input"),
      config: {
        label: "出差备注",
        required: false,
      },
    },
    {
      target: getConfigByTag(inputComponents, "el-input"),
      config: {
        label: "同行人",
        required: false,
      },
    },
  ]),
];
// .map( t => Object.assign( { cmpType: 'custom', t } ) )
// 组件rule的触发方式，无触发方式的组件不生成rule
// inputTable组件也有一份此常量的copy 如有改动 最好同步

export const trigger = {
  "el-input": "blur",
  "el-upload": "change",
  "el-input-number": "blur",
  "el-select": "change",
  "el-radio-group": "change",
  "el-checkbox-group": "change",
  "el-cascader": "change",
  "el-time-picker": "change",
  "el-date-picker": "change",
  "el-rate": "change",
  "fc-amount": "change",
  "fc-time-duration": "change",
  "fc-date-duration": "change",
  "fc-org-select": "input",
  "fc-dict": "input",
};
