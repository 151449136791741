<template>
    <div class="post-select">
        <div class="tags">
            <el-button
                v-if="buttonType === 'button'"
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="!disabled && open()"
                style="margin-bottom: 6px"
            >
                添加{{ title }}
            </el-button>
            <div class="post-tags" @click="!disabled ? open() : ''">
                <el-tag
                    v-bind="tagConfig"
                    class="post-tag"
                    :closable="!disabled"
                    v-for="item in selectedData"
                    :key="item.id"
                    @close="!disabled && onClose(item, 'selectedData')"
                >
                    {{ item.name }}
                </el-tag>
            </div>
        </div>
        <el-dialog
            :visible.sync="show"
            :title="title"
            width="70%"
            append-to-body
            :before-close="close"
        >
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-form
                        :model="queryParams"
                        ref="queryForm"
                        label-width="68px"
                        label-position="left"
                    >
                        <el-form-item label="岗位编码" prop="code">
                            <el-input
                                v-model="queryParams.code"
                                placeholder="请输入岗位编码"
                                clearable
                                size="small"
                                class="width-100"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item label="岗位名称" prop="name">
                            <el-input
                                v-model="queryParams.name"
                                placeholder="请输入岗位名称"
                                clearable
                                size="small"
                                class="width-100"
                                @keyup.enter.native="handleQuery"
                            />
                        </el-form-item>
                        <el-form-item label="状态" prop="status">
                            <el-select
                                v-model="queryParams.status"
                                placeholder="岗位状态"
                                clearable
                                size="small"
                                class="width-100"
                            >
                                <el-option
                                    v-for="dict in statusOptions"
                                    :key="dict.key"
                                    :label="dict.value"
                                    :value="dict.key"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button
                                type="primary"
                                icon="el-icon-search"
                                size="mini"
                                @click="handleQuery"
                                >搜索</el-button
                            >
                            <el-button
                                icon="el-icon-refresh"
                                size="mini"
                                @click="resetQuery"
                                >重置</el-button
                            >
                        </el-form-item>
                    </el-form>
                    <el-card class="box-card">
                        <div slot="header" class="clearfix">
                            <span>已选中岗位</span>
                        </div>
                        <div class="post-tags">
                            <div v-if="!setCheckedRow.length" class="tips">
                                暂无选择
                            </div>
                            <el-tag
                                v-bind="tagConfig"
                                class="post-tag"
                                v-for="item in setCheckedRow"
                                :key="item.id"
                                @close="onClose(item, 'setCheckedRow')"
                            >
                                {{ item.name }}
                            </el-tag>
                        </div>
                    </el-card>
                </el-col>
                <el-col :span="16">
                    <jf-table
                        ref="vxeTable"
                        :height="'500px'"
                        :loading="loading"
                        :columns="tableColumn"
                        :data="postList"
                        :toolbar-config="tableToolbar"
                        :query-params="queryParams"
                        @checkbox-change="checkboxChangeEvent"
                        @page-change="handleSizeChange"
                        :total="total"
                        :operate="false"
                    >
                        <template slot="status" slot-scope="{ row }">
                            {{ statusFormat(row) }}
                        </template>
                    </jf-table>
                </el-col>
            </el-row>
            <div class="d-flex justify-content-end mt-20">
                <el-button @click="close">关闭</el-button>

                <el-button type="primary" @click="onSubmit">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  <script>
import {
    listPost,
    getPost,
    delPost,
    addPost,
    updatePost,
    exportPost,
} from "@/api/core/system/post";
export default {
    model: {
        prop: "value",
        event: "input",
    },
    name: "post-select",
    props: {
        value: {
            type: Object | null,
            required: true,
        },
        tabList: {
            type: Array,
            default: () => ["dep"],
        },
        title: {
            type: String,
            default: "岗位列表",
        },
        buttonType: {
            type: String,
            default: "input",
        }, // or input
        searchable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxNum: {
            type: Number,
            default: 99,
        },
        tagConfig: {
            type: Object,
            default: () => ({
                type: "info",
                closable: true,
                "disable-transitions": false,
                hit: false,
                color: undefined,
                size: "small",
                effect: "light",
            }),
        },
    },
    data() {
        return {
            tabKeys: [],
            show: false,
            innerValue: null,
            selectedData: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 10,
                code: undefined,
                name: undefined,
                status: undefined,
            },
            // 工具栏
            tableToolbar: {
                // perfect: false,
                // zoom: true,
                // custom: false,
                // refresh: {
                // 	query: this.handleQuery
                // },
                // slots: {
                // 	buttons: 'toolbar_buttons'
                // }
            },
            // 列
            tableColumn: [
                // {
                // 	type: 'checkbox',
                // 	width: 60,
                // 	fixed: "left"
                // },
                //{field: 'id', title: '岗位编号', width: 100, fixed: "left"},
                {
                    field: "code",
                    title: "岗位编码",
                },
                {
                    field: "name",
                    title: "岗位名称",
                },
                {
                    field: "sort",
                    title: "岗位排序",
                },
                {
                    field: "status",
                    title: "状态",
                    width: 100,
                    // formatter: this.statusFormat,
                    slots: {
                        default: "status",
                    },
                    form_type: 2,
                    options: [],

                    options_label: "value",
                    options_value: "key",
                },
                //        {field: 'create_time', title: '创建时间', width: 180, formatter: "formatDate"},
                // {
                // 	field: '',
                // 	title: '操作',
                // 	resizable: false,
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: {
                // 		default: 'defaultopr'
                // 	}
                // }
            ],
            // 总条数
            total: 0,
            // 岗位表格数据
            postList: [],
            statusOptions: [],
            ids: [],
            setCheckedRow: [],
            loading: false,
        };
    },
    watch: {
        value: {
            handler: function (val) {
               console.log("value", val);
                if (!val || typeof val ==='string') return;
                this.innerValue = JSON.parse(JSON.stringify(this.value));
                this.selectedData = JSON.parse(JSON.stringify(this.value));
                // this.reloadCmpData();
            },
            immediate: true,
            deep: true,
        },
        // tabList: {
        //     handler: function (val) {
        //         this.tabKeys = [];
        //         val.forEach((t) =>
        //             this.tabKeys.push(typeof t === "string" ? t : t.key)
        //         );
        //         this.reloadCmpData();
        //     },
        //     immediate: true,
        // },
        disabled: {
            handler: function (val) {
                console.log("disabled-post", val);
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        selectedLabels() {
            return this.selectedData.map((t) => t.label).join(",");
        },
    },
    methods: {
        open() {
            this.show = true;
            this.setCheckedRow = JSON.parse(JSON.stringify(this.selectedData));
            this.getList();
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.resetForm("queryForm");
            this.handleQuery();
        },
        // 岗位状态字典翻译
        statusFormat(row) {
            return this.selectDictLabel(this.statusOptions, row.status);
        },
        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },
        checkboxChangeEvent({ records }) {
            if (
                this.setCheckedRow.length > this.maxNum * 1 ||
                records.length > this.maxNum
            ) {
                this.$message.error(`最多选择${this.maxNum}个`);
                
                records.forEach((item) => {
                    if(this.setCheckedRow.findIndex(o=>o.id === item.id)===-1){
                        this.$refs.vxeTable.$refs.xGrid.setCheckboxRow(item, false);
                    }
                   
                });
                return;
            }
            // const { records } = checked;
            this.setCheckedRow.push(
                ...records.map((o) => {
                    return { id: o.id, name: o.name };
                })
            );
            this.setCheckedRow = this.reduceSame(this.setCheckedRow);
            //过滤一个未选中的noCheckedList
            let noChecklist = this.postList.filter(
                (v) => !records.some((vi) => vi.id == v.id)
            );
            this.setCheckedRow = this.setCheckedRow.filter(
                (v) => !noChecklist.some((vi) => vi.id == v.id)
            );
        },
        onSubmit() {
            this.show = false;
            this.selectedData = JSON.parse(JSON.stringify(this.setCheckedRow));
            this.$emit("input", this.selectedData);
        },

        reduceSame(arr) {
            let obj = {};
            return arr.reduce(function (prev, item) {
                obj[item.id] ? "" : (obj[item.id] = 1 && prev.push(item));
                return prev;
            }, []);
        },

        /** 查询岗位列表 */
        getList() {
            this.loading = true;
            listPost(this.queryParams).then((response) => {
                this.single = true;
                this.multiple = true;
                this.postList = response.data.list;
                this.total = response.data.total;
                this.statusOptions = response.data.statusOptions.values || [];
                for (let i = 0; i < this.tableColumn.length; i++) {
                    if (this.tableColumn[i].field === "status") {
                        this.tableColumn[i].options = this.statusOptions;
                    }
                }
                this.$nextTick(() => {
                    if (this.setCheckedRow.length) {
                        this.postList.forEach((item) => {
                            if (
                                this.setCheckedRow.some(
                                    (checkedItem) => checkedItem.id === item.id
                                )
                            ) {
                                this.$refs.vxeTable.$refs.xGrid.setCheckboxRow(
                                    item,
                                    true
                                );
                            }
                        });
                    }
                });
                this.loading = false;
            });
        },
        close() {
            this.show = false;
            this.setCheckedRow = [];
        },

        onClose(val, list) {
            let index = this[list].findIndex((t) => t.id === val.id);
            if (index > -1) {
                this[list].splice(index, 1);
                if (list == "setCheckedRow") {
                    let item_index = this.postList.findIndex(
                        (t) => t.id === val.id
                    );
                    if (item_index > -1) {
                        this.$refs.vxeTable.$refs.xGrid.setCheckboxRow(
                            this.postList[item_index],
                            false
                        );
                    }
                }
            }

            // const list = this.innerValue[item.tabKey];
            // const index = list.findIndex(
            //     (t) => this.getKey(t, item.tabKey) === item.key
            // );
            // index > -1 && list.splice(index, 1);
            // this.initSelectedData();
            // this.$emit("input", this.innerValue);
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
    height: 70vh;
    overflow: auto;
}
.width-100 {
    width: 100%;
}
.box-card .post-tags {
    height: 185px;
    overflow-y: auto;
    .tips {
        // font-size: 12px;
        text-align: center;
        color: #909399;
    }
}
.post-tags {
    // display: flex;
    // flex-wrap: wrap;
    margin: -5px;

    .post-tag {
        margin: 5px !important;
    }
}
</style>
  <style lang="stylus" scoped>
  .tags {
      .input-box.as-input {
          border: 1px solid #DCDFE6;
          padding-left: 6px;
          font-size: 12px;
          min-height: 32px;
          line-height: 30px;
          border-radius: 4px;
          background: white;
          color: #606266;
          cursor: pointer;
      }

      .org-tag {
          margin-right: 6px;
          max-width: 6rem;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
          padding-right: 1rem;
          vertical-align: middle;

          >>> .el-tag__close {
              position: absolute;
              right: 2px;
              top: 50%;
              margin-top: -7px;
          }
      }
  }
</style>