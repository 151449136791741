<template>
    <div class="app-container">
        <generate-form
            ref="qform"
            v-if="queryParams_formConfig"
            :conf="queryParams_formConfig"
        />

        <el-form ref="queryForm" :inline="true" label-width="68px">
            <el-form-item>
                <el-button
                    type="primary"
                    icon="el-icon-search"
                    size="mini"
                    @click="handleQuery"
                    >搜索</el-button
                >
                <el-button
                    icon="el-icon-refresh"
                    size="mini"
                    @click="resetQuery"
                    >重置</el-button
                >
            </el-form-item>
        </el-form>

        <vxe-grid
            resizable
            ref="xGrid"
            stripe
            show-overflow
            highlight-hover-row
            :loading="loading"
            :toolbar-config="tableToolbar"
            :columns="tableColumn"
            :data="dataList"
            @checkbox-change="checkboxChangeEvent"
            @checkbox-all="checkboxChangeEvent"
            :max-height="'500px'"
        >
            <template #html="{ row }">
                <div class="" v-html="row.v_d"></div>
            </template>
            <!--自定义空数据模板-->
            <template #empty>
                <span>
                    <p>暂无数据</p>
                </span>
            </template>
            <template #flowstatus="{ row }">
                <el-tag
                    v-for="dict in statusOptions"
                    :key="dict.key"
                    :type="dict.type"
                    v-if="dict.key == row.process_status"
                    >{{ dict.value }}</el-tag
                >
            </template>
        </vxe-grid>

        <vxe-pager
            background
            size="small"
            :loading="loading"
            :current-page="queryParams.pageNum"
            :page-size="queryParams.pageSize"
            :total="total"
            @page-change="handleSizeChange"
        >
        </vxe-pager>

        <!-- 添加或修改对话框 -->

        <!--高级查询对话框-->
        <el-dialog
            title="高级查询"
            :visible.sync="advqueryopen"
            width="1300px"
            append-to-body
        >
            <el-row :gutter="20">
                <el-col :span="15" :xs="24">
                    <vxe-grid
                        border
                        resizable
                        keep-source
                        ref="xAdvqueryGrid"
                        id="id_xAdvqueryGrid"
                        height="500"
                        :columns="advqueryColumn"
                        :toolbar-config="advqueryToolbar"
                        :data="advqueryData"
                        :custom-config="{ storage: true }"
                        :edit-config="{
                            trigger: 'click',
                            mode: 'row',
                            showStatus: true,
                        }"
                        @toolbar-button-click="advqtoolbarButtonClickEvent"
                    >
                        <template #defaultadvqueryopr="{ row }"> </template>
                    </vxe-grid>
                </el-col>
                <el-col :span="9" :xs="24">
                    <vxe-grid
                        border
                        resizable
                        keep-source
                        ref="xAdvqueryGrid_tempfilter"
                        id="id_xAdvqueryGrid_tempfilter"
                        height="500"
                        :columns="advqueryColumn_tempfilter"
                        :data="advqueryData_tempfilter"
                    >
                        <template #defaultadvqueryopr_tempfilter="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleadvqueryApplytpl(row)"
                                >应用
                            </el-button>

                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleadvqueryDeltpl(row)"
                                >删除
                            </el-button>
                        </template>
                    </vxe-grid>
                </el-col>
            </el-row>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="advquery_save"
                    >确 定</el-button
                >
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <!--保存模版-->
        <el-dialog
            :close-on-click-modal="false"
            title="保存模版"
            :visible.sync="advquery_tempsave"
            width="800px"
            append-to-body
        >
            <el-form
                ref="advquery_tempsaveform"
                :model="advquery_tempsaveform"
                :rules="advquery_tempsaverules"
                label-width="80px"
            >
                <el-form-item label="模版名称" prop="tempname">
                    <el-input
                        v-model="advquery_tempsaveform.tempname"
                        placeholder="请输入模版名称"
                    />
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="advquery_save"
                    >确 定</el-button
                >
            </div>
        </el-dialog>

        <!--导出数据-->
        <el-dialog
            :close-on-click-modal="false"
            title="导出数据"
            :visible.sync="exportdialog"
            width="800px"
            append-to-body
        >
            <el-checkbox
                :indeterminate="exportisIndeterminate"
                v-model="exportcheckAll"
                @change="handleexportCheckAllChange"
                >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>

            <el-checkbox-group v-model="exportfields">
                <el-checkbox
                    v-for="field in tableColumn"
                    v-if="field.field"
                    :label="field.field"
                    :key="field.field"
                    >{{ field.title }}</el-checkbox
                >
            </el-checkbox-group>

            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="doExport">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog :visible.sync="dialogVisible"> </el-dialog>

        <el-dialog title="查看二维码" :visible.sync="viewqrcodeinfourl">
            <img :src="viewqrcodeinfourl" />
        </el-dialog>

    

        <admin-select
            ref="transferUserSelect"
            @doSubmit="selectUsersToInput"
            :limit="1"
        ></admin-select>
    </div>
</template>
<script>
import GenerateForm from "@/components/jpgflow/DynamicForm/GenerateForm";
import AdminSelect from "@/components/AdminSelect/AdminSelect";
import { getToken } from "@/utils/auth";
import {
    listTable,
    getTable,
    delTable,
    addTable,
    editTable,
    advquerydelTable,
    advquerysaveTable,
    advquerylistTable,
    exportTable,
} from "@/api/bpm/table";
import QRCode from "qrcode";
import TabledataSvr from "@/api/bpm/tabledata";
import { listMenu } from "@/api/core/system/menu";
// import BmpTask from "../task/task";
import { transferTask } from "@/api/bpm/task";
import { FormxShowList } from "@/api/form/data";
import { runProcessList } from "@/api/bpm/process";

export default {
    components: {
        // BmpTask,
        GenerateForm,
        AdminSelect,
    },
    name: "bpm_formdata_list",
    props: {
        config: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            selectrow: null,
            statusOptions: [
                { key: "-1", value: "挂起", type: "danger" },
                { key: "0", value: "草稿", type: "" },
                { key: "1", value: "待审批", type: "warning" },
                { key: "2", value: "审批通过", type: "success" },
                { key: "3", value: "撤回", type: "danger" },
                { key: "4", value: "驳回", type: "danger" },
                { key: "5", value: "终止", type: "danger" },
                { key: "6", value: "作废", type: "danger" },
            ],

            queryParams_formConfig: null,
            queryParams_formConfigOld: null,
            notshowfield: [
                "id",
                "rev",
                "flow_id",
                "report_id",
                "resource_id",
                //"process_status",
                "starter_id",
                "starter_account",
                // "starter_name",
                "dept_id",
                "vars",
                "fields",
                "ext",
                "create_time",
                "update_time",
            ],
            mustshowfield: ["starter_name", "process_status"],
            viewqrcodeinfourl: null,
            rowbtninfo: {
                114: [
                    {
                        label: "二维码",
                        name: "qrcode",
                    },
                ],
                10003: [
                    {
                        label: "转派",
                        name: "tansfer",
                    },
                ],
                97: [
                    {
                        label: "转派",
                        name: "tansfer",
                    },
                ],
            },
            toolbtninfo: {
                114: [
                    {
                        label: "下载二维码",
                        name: "downqrcode",
                    },
                ],
            },
            showtask: false,
            flowid: null,
            flowname: "",
            businessname: "table", //请勿改动
            exportfields: [],
            exportcheckAll: false,
            exportisIndeterminate: false,
            exportdialog: false,

            //编辑器配置
            ueConfig: {
                // 初始容器高度
                initialFrameHeight: 300,
                // 初始容器宽度
                initialFrameWidth: "100%",
                // 上传文件接口
                serverUrl: process.env.VUE_APP_BASE_API + "/file/ueditorupload",
                enableAutoSave: true,
                elementPathEnable: false,
                wordCount: false,
                UEDITOR_HOME_URL: "/ueditor/",
            },
            tableToolbar: {
                perfect: true,
                zoom: true,
                custom: true,
                refresh: {
                    query: this.handleQuery,
                },
                // slots: {
                //     buttons: "toolbar_buttons",
                // },
            },

            // 列
            tableColumn: [],

            advqueryColumn: [
                {
                    field: "qfield",
                    title: "查询字段",
                    width: 180,
                    fixed: "left",
                    editRender: {
                        name: "select",
                        options: [
                            { value: "dd", label: "cxvs" },
                            { value: "zz", label: "cxvs" },
                        ],
                    },
                },
                {
                    field: "qopr",
                    title: "操作符",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "qvalue",
                    title: "值",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "input" },
                },
                {
                    field: "qlogic",
                    title: "逻辑",
                    width: 180,
                    fixed: "left",
                    editRender: { name: "select", options: [] },
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 110,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr" },
                },
            ],
            advqueryColumn_tempfilter: [
                {
                    field: "tempname",
                    title: "模版名称",
                    width: 200,
                    fixed: "left",
                },
                {
                    field: "",
                    title: "操作",
                    resizable: false,
                    width: 180,
                    fixed: "right",
                    align: "center",
                    slots: { default: "defaultadvqueryopr_tempfilter" },
                },
            ],
            advqueryData_tempfilter: [],
            advqueryopen: false,
            advquery_tempsave: false,
            advqueryData: [],
            advqueryToolbar: {
                buttons: [
                    { code: "myInsert", name: "新增" },
                    { code: "dofilter", name: "使用此组合条件查询" },
                ],
            },
            upload: {
                headers: { Authorization: getToken() },
                url: process.env.VUE_APP_BASE_API + "/file/upload?public=1",
            },
            dialogVisible: false,

            // 遮罩层
            loading: true,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 表格数据
            dataList: [],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 字典

            // 查询参数
            queryParams: {
                fieldval: {},
                sortinfo: { id: "desc" },
                pageNum: 1,
                pageSize: 10,
                querytype: {},
            },
            // 表单参数
            form: {},
            advquery_tempsaveform: {},
            advquery_tempsaverules: {
                tempname: [
                    {
                        required: true,
                        message: "模版名称不能为空",
                        trigger: "blur",
                    },
                ],
            },
            // 表单校验
            rules: {},
        };
    },
    created() {},
    methods: {
        init(flowid) {
            this.flowid = flowid;

            TabledataSvr.tableInfo(this.flowid).then((rs) => {
                var rows = JSON.parse(rs.data.Rows);
                this.setqformConfig(JSON.parse(rs.data.ResourceData));
                this.settablecolum(rows, JSON.parse(rs.data.ResourceData));
            });

            listMenu({ auth: "/bpm/tabledata/list/" + this.flowid }).then(
                (rs) => {
                    if (rs.data.list.length > 0) {
                        this.flowname = rs.data.list[0].title;
                        this.settagtitle(this.flowname);
                    }
                }
            );
        },
        selectUsersToInput(users) {
            let userIds = users
                .map((user) => {
                    return user["id"];
                })
                .join(",");
            //获取选择的信息

            if (userIds == "") {
                return;
            }

            let taskIds = this.selectrow.task_id;
            transferTask({
                taskIds: taskIds,
                code: "transfer",
                comment: "转派",
                candidates: userIds,
                vars: "{}",
            }).then((response) => {
                this.msgSuccess("转派成功");
                this.getList();
            });
        },
        handleView(row) {
            let tparam = {
                id: row.id,
                flow_id: this.flowid,
                viewType: "run",
            };
            runProcessList(tparam).then((response) => {
                let dataList = response.data.list;
                // console.log("runProcessList", dataList);
                this.$router.push({
                    path: "/bpm/task/view",
                    query: {
                        resourceId: row.resource_id,
                        id: row.id,
                        taskId: dataList.task_id,
                        title: this.flowname,
                    },
                });
            });
        },
        async getFormsrvData(mid) {
            var param = {
                fieldval: {},
                sortinfo: null,
                pageNum: 1,
                pageSize: 100,
            };
            let res = await FormxShowList(mid, param);
            return res.data.list;
        },

        setqformConfig(res) {
            var tfields = [];
            res.processData = {};
            res.basicSetting = {};
            res.formData.fields.forEach((field) => {
                if (field.showpos && field.showpos.indexOf("查询") >= 0) {
                    field.span = 6;
                    field.required = false;
                    field.disabled = false;
                    if (field.formsvr && field.formsvr.id) {
                        var formdatalist = this.getFormsrvData(
                            field.formsvr.id
                        );
                        formdatalist.then((rs) => {
                            let toption = [];
                            for (let ii in rs) {
                                let onerec = rs[ii];
                                var optionValue = field.formsvr.optionValue
                                    ? field.formsvr.optionValue
                                    : "id";
                                toption.push({
                                    label: onerec[field.formsvr.optionLabel],
                                    value: onerec[optionValue],
                                    originData: onerec,
                                });
                            }

                            field.options = toption;
                        });
                    }

                    tfields.push(field);
                }
            });

            res.formData.fields = tfields;
            res.formData.labelPosition = "right";
            //res.formData.labelWidth = "100";
            this.queryParams_formConfig = res.formData;
            this.queryParams_formConfigOld = JSON.stringify(res.formData);
        },
        startsuccess() {
            this.showtask = false;
        },
        handleToolBtnAction(item, row) {
            switch (item.name) {
                case "downqrcode":
                    this.$confirm("是否下载二维码？", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        this.msgSuccess("此功能正在开发中...");
                    });
                    break;
            }
        },
        handleBtnAction(item, row) {
            // console.log("row", row);
            this.selectrow = row;
            switch (item.name) {
                case "qrcode":
                    let text =
                        location.origin +
                        "/sbxx/qrcode/" +
                        row.id +
                        "/" +
                        this.flowid;
                    this.getQRcode(text);
                    break;
                case "tansfer":
                    if (row.process_status != 1) {
                        this.msgError("只能转派审核中的流程");

                        return;
                    }

                    this.$refs.transferUserSelect.init();

                    break;
            }
        },
        getQRcode(text) {
            QRCode.toDataURL(
                text,
                { errorCorrectionLevel: "L", margin: 2, width: 512 },
                (err, url) => {
                    if (err) throw err;
                    this.viewqrcodeinfourl = url;
                    // this.QRImgUrl = url;
                    // console.log("url", url);
                    // window.open(url);
                }
            );
        },

        settablecolum(data, recdata) {
            let tableColumn = [{ type: "checkbox", width: 60, fixed: "left" }];
            let showposfield = [];
            let fileconf = [];
            recdata.formData.fields.forEach((field) => {
                //console.log("settablecolum field",field)
                if (field.showpos && field.showpos.indexOf("列表") >= 0) {
                    showposfield.push(field.vModel);
                    fileconf.push(field);
                }
            });

            for (let i in data) {
                let item = data[i];
                if (
                    this.notshowfield.indexOf(item.name) == -1 &&
                    item.name.indexOf("static_") == -1
                ) {
                    //如果设置了显示位置 且没有在列表
                    var findex = showposfield.indexOf(item.name);

                    if (
                        showposfield.length > 0 &&
                        findex == -1 &&
                        this.mustshowfield.indexOf(item.name) == -1
                    ) {
                        continue;
                    }

                    let times = ["create_time", "update_time", "delete_time"];
                    // item.name.indexOf("_time")
                    var formatter = "";
                    var isdatefield =
                        times.findIndex((o) => o == item.name) != -1;
                    if (isdatefield) {
                        formatter = "formatDate";
                    }
                    let tcolinfo = {
                        field: item.name,
                        title: item.comment,
                        minWidth: 80,
                        formatter: "",
                    };

                    if (
                        findex >= 0 &&
                        fileconf[findex].type == "dict" &&
                        fileconf[findex].dictType != null
                    ) {
                        formatter = this.dictFormat;
                        tcolinfo.dictType = fileconf[findex].dictType;
                    }

                    if (
                        findex >= 0 &&
                        fileconf[findex].formsvr != null &&
                        fileconf[findex].formsvr.id != null
                    ) {
                        formatter = this.formsvrFormat;
                        tcolinfo.formsvr = fileconf[findex].formsvr;
                    }

                    tcolinfo.formatter = formatter;

                    if (item.name == "process_status") {
                        tcolinfo.slots = {
                            default: "flowstatus",
                        };
                        tcolinfo.title = "审批状态";
                    }
                    if (item.name == "v_d") {
                        tcolinfo.slots = {
                            default: "html",
                        };
                    }
                    tableColumn.push(tcolinfo);
                }
            }

            // tableColumn.push({
            //     field: "",
            //     title: "操作",
            //     width: 230,
            //     fixed: "right",
            //     align: "center",
            //     slots: { default: "defaultopr" },
            // });
            // console.log("tableColumn",tableColumn);
            this.tableColumn = tableColumn;

            for (let i = 0; i < this.tableColumn.length; i++) {
                const col = this.tableColumn[i];
                if (col.dictType) {
                    this.getDicts(col.dictType).then((response) => {
                        if (response.code == 0) {
                            col.dictData = response.data;
                            this.dataList = [].concat(this.dataList);
                        }
                    });
                }

                if (col.formsvr) {
                    var formdatalist = this.getFormsrvData(col.formsvr.id);

                    formdatalist.then((rs) => {
                        // console.log("rs",rs);
                        col.formData = rs;
                        this.dataList = [].concat(this.dataList);
                    });
                } //if
            } //for

            this.getList();
        },
        dictFormat(e) {
            const col = this.tableColumn[e.columnIndex];
            if (col.dictData) {
                const values = e.cellValue ? e.cellValue.split(",") : [];
                const labels = [];
                for (let i = 0; i < values.length; i++) {
                    const v = values[i];
                    for (let j = 0; j < col.dictData.values.length; j++) {
                        const item = col.dictData.values[j];
                        if (v == item.key) {
                            labels.push(item.value);
                            break;
                        }
                    }
                }
                return labels.join(",");
            }
            return e.cellValue;
        },
        formsvrFormat(e) {
            const col = this.tableColumn[e.columnIndex];

            if (col.formData && col.formsvr) {
                var optionValue = col.formsvr.optionValue
                    ? col.formsvr.optionValue
                    : "id";

                var tar = [];
                for (let ii in col.formData) {
                    let onerec = col.formData[ii];

                    if (this.isjson(e.cellValue)) {
                        var vallist = JSON.parse(e.cellValue);
                        // console.log("vallist",vallist);

                        for (let ik in vallist) {
                            if (vallist[ik] == onerec[optionValue]) {
                                tar.push(onerec[col.formsvr.optionLabel]);
                            }
                        }
                    }
                }
                return tar.join(",");
            }
            return e.cellValue;
        },

        settagtitle(title) {
            this.$route.meta.title = title;
            document.title = title;
            for (var i in this.$router.app.$store.state.tabsBar.visitedRoutes) {
                var onetag =
                    this.$router.app.$store.state.tabsBar.visitedRoutes[i];
                if (onetag.path == this.$route.path) {
                    this.$router.app.$store.state.tabsBar.visitedRoutes[
                        i
                    ].title = title;
                    return true;
                }
            }
            // this.$router.app.$store.state.tagsView.visitedViews[1].title="sdfsdfs"
        },

        //表单上传字段成功处理
        handleImportSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            var field = response.data.return_field;

            this.form[field] = this.form[field] || [];
            this.form[field].push(response.data.url);
            this[field + "_fileList"].push({ url: response.data.url });
        },
        handleUpload() {},
        /** 打开导入表弹窗 */
        openImportTable() {
            this.$refs.import.show();
        },
        handleExport() {
            if (this.total > 0) {
                this.exportdialog = true;
            } else {
                this.msgError("暂无数据可导出!");
            }
        },
        doExport() {
            const queryParams = this.queryParams;
            this.$confirm("是否确认导出?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    return exportTable(
                        queryParams,
                        this.exportfields,
                        this.tableColumn
                    );
                })
                .then((response) => {
                    this.download(response.file);
                })
                .catch(function () {});
        },
        handleexportCheckAllChange(val) {
            this.exportfields = val
                ? this.tableColumn.map((item) => {
                      return item.field;
                  })
                : [];
            this.exportisIndeterminate = false;
        },
        //删除查询模版
        handleadvqueryDeltpl(row) {
            var ids = row.id;
            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    advquerydelTable(ids)
                        .then(() => {
                            const $grid = this.$refs.xAdvqueryGrid_tempfilter;
                            $grid.remove(row);
                        })
                        .then(() => {
                            this.msgSuccess("删除成功");
                        });
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        //打开高级查询
        handleAdvquery() {
            advquerylistTable(this.businessname).then((response) => {
                this.advqueryData_tempfilter = response.data.list;
                this.advqueryopen = true;
            });
        },
        //保存条件
        advquery_save() {
            const $grid = this.$refs.xAdvqueryGrid;
            var alldata = $grid.getTableData();
            if (alldata.fullData.length == 0) {
                return false;
            }

            var opstr = {};
            var filteroptview = [];
            var Qlogicar = this.defaultQlogic();
            var queryParams = {};
            var fields = {},
                Qlogic = {};

            for (var i in this.tableColumn) {
                var col = this.tableColumn[i];
                if (col.field != null && col.field != "") {
                    fields[col.field] = col.title;
                }
            }

            for (var i in Qlogicar) {
                var lgc = Qlogicar[i];
                Qlogic[lgc.value] = lgc.label;
            }

            for (var i in alldata.fullData) {
                var onerow = alldata.fullData[i];
                queryParams[onerow.qfield] = onerow.qvalue;
                opstr[onerow.qfield] = onerow.qopr;
                filteroptview.push(
                    fields[onerow.qfield] +
                        " " +
                        Qlogic[onerow.qopr] +
                        "" +
                        onerow.qvalue
                );
            }

            queryParams.opstr = opstr;
            this.advquery_tempsaveform["businessname"] = this.businessname;
            this.advquery_tempsaveform["filteropt"] =
                JSON.stringify(queryParams);
            this.advquery_tempsaveform["filteroptview"] =
                filteroptview.join(";");

            this.$refs["advquery_tempsaveform"].validate((valid) => {
                if (valid) {
                    advquerysaveTable(this.advquery_tempsaveform).then(
                        (response) => {
                            if (response.code === 0) {
                                this.msgSuccess("保存成功");
                                this.advquery_tempsave = false;
                                this.handleAdvquery();
                            }
                        }
                    );
                }
            });
        },

        //应用查询模版
        handleadvqueryApplytpl(row) {
            var Filteropt = JSON.parse(row.filteropt);

            Filteropt["pageNum"] = 1;
            Filteropt["pageSize"] = 10;
            this.queryParams = Filteropt;
            this.advqueryopen = false;
            setTimeout(() => {
                this.getList();
            }, 300);
        },
        //删除临时条件
        handleadvqueryDeleted(row) {
            const $grid = this.$refs.xAdvqueryGrid;

            this.$confirm("是否确认删除?", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    $grid.remove(row);
                })
                .then(() => {
                    this.msgSuccess("删除成功");
                })
                .catch(function () {});
        },
        advqtoolbarButtonClickEvent({ code }) {
            const $grid = this.$refs.xAdvqueryGrid;
            switch (code) {
                case "myInsert":
                    var row = $grid.insert({
                        qfield: "",
                        qopr: "=",
                        qlogic: "AND",
                    });

                    $grid.setActiveRow($grid.getData(0));
                    break;

                case "dosavefilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }
                    this.advquery_tempsave = true;
                    break;
                case "dofilter":
                    var alldata = $grid.getTableData();
                    if (alldata.fullData.length == 0) {
                        return false;
                    }

                    var opstr = {};

                    var queryParams = {
                        pageNum: 1,
                        pageSize: 10,
                        opstr: {},
                    };

                    for (var i in alldata.fullData) {
                        var onerow = alldata.fullData[i];
                        queryParams[onerow.qfield] = onerow.qvalue;
                        opstr[onerow.qfield] = onerow.qopr;
                    }

                    queryParams.opstr = opstr;
                    this.queryParams = queryParams;
                    this.advqueryopen = false;
                    setTimeout(() => {
                        this.getList();
                    }, 300);
                    break;
            }
        },

        checkboxChangeEvent({ records }) {
        this.$emit('change',records)
        },

        handleSizeChange({ currentPage, pageSize }) {
            this.queryParams.pageNum = currentPage;
            if (this.queryParams.pageSize == pageSize) {
                this.getList();
            } else {
                this.queryParams.pageSize = pageSize;
                this.handleQuery();
            }
        },

        /** 查询列表 */
        getList() {
            this.loading = true;

            TabledataSvr.tableDataList(this.queryParams, this.flowid)
                .then((response) => {
                    let datalist = JSON.parse(response.data.rows);
                    // console.log("datalist", datalist);
                    this.dataList = this.formatdata(datalist.rows);
                    this.total = datalist.total;
                    this.loading = false;
                })
                .catch((_) => {
                    this.loading = false;
                });
        },
        formatdata(list) {
            list.forEach((item) => {
                for (let field in item) {
                    if (
                        this.notshowfield.indexOf(field) == -1 &&
                        this.isjson(item[field])
                    ) {
                        let tjs = JSON.parse(item[field]); //{"dep":[{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}]}
                        // console.log("tjs",tjs)
                        let tvalue = [];
                        if (typeof tjs == "object" && !Array.isArray(tjs)) {
                            for (let k in tjs) {
                                let tav = tjs[k];
                                if (
                                    typeof tav == "object" &&
                                    (k == "dep" || k == "user")
                                ) {
                                    //数组 或对象 [{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}]
                                    for (let a in tav) {
                                        let tba = tav[a];
                                        for (let bf in tba) {
                                            //{"deptId":100,"deptName":"安全管理系统","nodeId":"dept_100"}
                                            if (
                                                bf == "nickName" ||
                                                bf == "deptName"
                                            ) {
                                                tvalue.push(tba[bf]); //取值 安全管理系统
                                            }
                                        }
                                    }
                                }
                            }
                            item[field] = tvalue.join(","); //最终的值
                        }
                    }
                }
            });
            return list;
        },
        isjson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        // $comment字典翻译

        // 取消按钮
        cancel() {
            this.open = false;
            this.advqueryopen = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                name: undefined,
                table_name: undefined,
                table_comment: undefined,
            };
            this.resetForm("form");
        },
        /** 搜索按钮操作 */
        handleQuery() {
            var qdatapri = this.$refs.qform.getData(false);
            qdatapri.then((qdata) => {
                // console.log("qdata", qdata);
                this.queryParams.fieldval = {};
                for (let qfield in qdata) {
                    let qvalue = qdata[qfield];

                    if (typeof qvalue === "string" && qvalue && qvalue != "") {
                        this.queryParams.fieldval[qfield] = qvalue;
                        this.queryParams.querytype[qfield] = "like";
                        // && qvalue * 1 === 0
                    } else if (qvalue && qvalue != "") {
                        this.queryParams.fieldval[qfield] = qvalue;
                    } else if (typeof qvalue === "number" && qvalue * 1 === 0) {
                        this.queryParams.fieldval[qfield] = qvalue;
                    }
                }

                this.queryParams.pageNum = 1;

                this.getList();
            });

            // this.queryParams.pageNum = 1;
            // this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            // this.resetForm("queryForm");
            this.queryParams_formConfig = null;
            this.$nextTick(() => {
                this.queryParams_formConfig = JSON.parse(
                    this.queryParams_formConfigOld
                );
            });

            this.queryParams.pageNum = 1;
            this.queryParams.fieldval = {};
            this.getList();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.$emit("change", selection);
            // this.ids = selection.map((item) => item.id);
            // this.single = selection.length !== 1;
            // this.multiple = !selection.length;
        },
    }, //methods结束
};
</script>
