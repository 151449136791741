<template>
    <div class="qrcode-container">
        <img :src="qrcode" alt="" />
    </div>
</template>
<script>
import QRCode from "qrcode";
import { debounce } from "@/utils";
export default {
    name: "QrCode",
    props: {
        defaultValue: {
            type: String,
            default: "",
        },
        qrCodeSize: {
            type: [Number,String],
            default: 200,
        },
        level: {
            type: String,
            default: "L",
        },
        bgColor: {
            type: String,
            default: "#ffffff",
        },
        color: {
            type: String,
            default: "#000",
        },
        url: {
            type: String,
            default: "",
        }
    },

    data() {
        return {
            qrcode: "",
        };
    },
    watch: {
        defaultValue: {
            handler(val) {
                // console.log(val,'测试')
                debounce(this.makeQrcode(), 1000);
            },
            deep: true,
            immediate: true,
        },
        bgColor: {
            handler(val) {
                debounce(this.makeQrcode(), 1000);
            },
            deep: true,
            immediate: true,
        },
        url: {
            handler(val) {
              console.log(val,'value')
              this.makeQrcode();
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.makeQrcode();
    },
    methods: {
        makeQrcode() {
            
            let text = this.url|| this.defaultValue;
       
            QRCode.toDataURL(text, {
                errorCorrectionLevel: this.level,
                width: this.qrCodeSize,
                marging:1,
                color: { dark: this.color, light: this.bgColor },
            })
                .then((url) => {
                    this.qrcode = url;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
    },
};
</script>
