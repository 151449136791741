import { listUser} from "@/api/core/system/user";
import { listDept, treeselect as deptTree } from "@/api/core/system/dept";
import { listRole } from "@/api/core/system/role";
import { listPost } from "@/api/core/system/post";
import { handleTree } from "@/utils/ruoyi"

// 获取组织机构根节点
export async function GET_DEPT_ROOT() {
  let list = ( await listDept({parent_id:0,ignore_datascope:0}) ).data.list
  let nodes = []
  /*for(let i=0;i<list.length;i++) {
    let row = list[i]
    if (row.parent_id == 0) {
      nodes.push({deptId: row.id, deptName: row.name})
    }
  }*/
  const treeList = handleTree(list, "id", "parent_id")
  for(let i=0;i<treeList.length;i++) {
    let row = treeList[i]
    nodes.push({deptId: row.id, deptName: row.name})
  }
  return nodes
}
// 根据部门id获取分页人员信息
export async function GET_PAGE_EMPLOYEE(data) {
  let list = ( await listUser(data) ).data.list
  let nodes = []
  for(let i=0;i<list.length;i++) {
    let row = list[i]
    nodes.push({userId:row.id, userName: row.username,ignore_datascope:0, nickName:row.nickname,realname:row.realname,
         deptId:row.dept_id, deptName: row.dept?row.dept.name:"" })
  }
  return nodes
}
// 获取组织机构子节点
export async function GET_DEPT_TREE(data) {
  let list = ( await listDept(data) ).data.list
  let nodes = []
  for(let i=0;i<list.length;i++) {
    let row = list[i]
    nodes.push({deptId:row.id, deptName: row.name, parentDeptId: row.parent_id })
  }
  return nodes
}
// 获取组织机构下人员信息
export async function GET_USER_BY_DEPT(data) {
  let list = ( await listUser(data) ).data.list
  let nodes = []
  for(let i=0;i<list.length;i++) {
    let row = list[i]
    nodes.push({userId:row.id, userName: row.username,nickName:row.nickname,realname:row.realname, deptId:row.dept_id, deptName: row.dept.name })
  }
  return nodes
}
//获取角色信息
export async function GET_ROLES(data) {
  let list = ( await listRole(data) ).data.list
  let nodes = []
  for(let i=0;i<list.length;i++) {
    let row = list[i]
    nodes.push({roleId:row.id, roleName: row.name})
  }
  return nodes
}
//获取岗位信息
export async function GET_POSTS(data) {
  let list = ( await listPost(data) ).data.list
  let nodes = []
  for(let i=0;i<list.length;i++) {
    let row = list[i]
    nodes.push({postId:row.id, postName: row.name})
  }
  return nodes
}
