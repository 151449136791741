/* eslint-disable no-useless-escape */
/* eslint-disable no-prototype-builtins */
import { GET_DEPT_ROOT, GET_DEPT_TREE, GET_USER_BY_DEPT, GET_PAGE_EMPLOYEE, GET_ROLES, GET_POSTS } from '@/api/bpm'
import store from '@/store'
const toHump = name => name.replace(/\_(\w)/g, function (all, letter) {
    return letter.toUpperCase()
})
// 需要自行设置nodeID  重要！！！ 
function getDepChildNode(orgId) {
    return GET_DEPT_TREE({ parent_id: orgId, ignore_datascope: 0 })
}
// 需要返回一个promise
function loadDepOrUser(node, loadDep = true) {
    let nodeData = []
    if (node.level === 0) { // 根目录
        nodeData = getRootDept()  // 获取根节点
    }
    else if (loadDep /* node.level === 1*/) {
        nodeData = getDepChildNode(node.data.deptId)  // 获取部门节点
    } else if (!loadDep /*&& node.level >= 2*/) {
        // 获取子部门及部门下人员
        return getDepChildNode(node.data.deptId).then(deptData => {
            return GET_PAGE_EMPLOYEE({ dept_id: node.data.deptId, with_sub_dept_user: false, ignore_datascope: 0, pageNum: 1, pageSize: 50 }).then(userData => {
                return new Promise(function (resolve) {
                    return resolve(deptData.concat(userData))
                })
            })
        })
    }
    return nodeData
}
// 获取组织结构根节点
// 需要自行设置nodeID  重要！！！
function getRootDept() {
    let res = []
    try {
        res = GET_DEPT_ROOT()
    } catch (err) {
    }
    return res
}

function loadDepData(node) {
    return loadDepOrUser(node) // 返回的promise
}

function loadUserData(node) {
    return loadDepOrUser(node, false)  // 返回的promise
}
function getRoles(node) {
    let nodeData = []
    if (node.level === 0) {
        nodeData = GET_ROLES({ pageNum: 1, pageSize: 9999 })
    }
    return nodeData
}
//加载角色数据
function loadRoleData(node) {
    return getRoles(node)
}
//加载表单组件数据
function loadFormData(node) {
    let nodeData = []
    store.state.jpgflow.formItemList.forEach(item => {
        if (item.tag == 'fc-org-select' && item.type == 'user') {
            nodeData.push({
                formId: item.formId,
                label: item.label,
                type: item.type,
                vModel: item.vModel,
            })
        }
    })
    return new Promise(function (resolve) {
        return resolve(nodeData)
    })
}
//加载主管数据
function loadDirectorData(node) {
    let nodeData = []
    if (node.level === 0) {
        for (let i = 0; i < 5; i++) {
            nodeData.push({
                directorId: i + 1,
                directorName: i == 0 ? '直接主管' : '第' + (i + 1) + '级主管'
            })
        }
    }
    return new Promise(function (resolve) {
        return resolve(nodeData)
    })
}
function getPosts(node) {
    let nodeData = []
    if (node.level === 0) {
        nodeData = GET_POSTS({ pageNum: 1, pageSize: 9999 })
    }
    return nodeData;
}
//加载岗位数据
function loadPostData(node) {
    return getPosts(node)
}
const defaultOption = {
    tabName: '部门',  // 选项卡名称
    tabKey: 'dep', //选项卡键值 传入的selected要和键值保持一致 eg: {dep: [], role: []}
    children: 'children', // 子节点标志
    // 生成每个节点的id 保证唯一
    nodeId: function (data) {
        if (data.hasOwnProperty('userId')) {
            return "user_" + data.userId
        } else if (data.hasOwnProperty('roleId')) {
            return "role_" + data.roleId
        } else if (data.hasOwnProperty('postId')) {
            return "post_" + data.postId
        } else if (data.hasOwnProperty('directorId')) {
            return "director_" + data.directorId
        } else if (data.hasOwnProperty('formId')) {
            return "form_" + data.formId
        }
        return "dept_" + data.deptId
    },
    // 生成节点的名称 可选值 string | function
    label: function (data, node) {
        if (data.hasOwnProperty('userId')) {
            if (data.realname == null || data.realname == "") {
                return data.nickName;
            }

            return data.realname;
        } else if (data.hasOwnProperty('roleId')) {
            return data.roleName
        } else if (data.hasOwnProperty('postId')) {
            return data.postName
        } else if (data.hasOwnProperty('directorId')) {
            return data.directorName
        } else if (data.hasOwnProperty('formId')) {
            return data.label
        }
        return data.deptName
    },
    // 判断是否为叶子节点 可选值 string | function
    isLeaf: function (data, node) {
        if (data.hasOwnProperty('userId')) {
            // 含有userId为人员  且为叶子节点
            return true
        } else if (data.hasOwnProperty('roleId')) {
            return true
        } else if (data.hasOwnProperty('postId')) {
            return true
        } else if (data.hasOwnProperty('directorId')) {
            return true
        } else if (data.hasOwnProperty('formId')) {
            return true
        }
        return false
    },
    // 搜索后的结果如果需要展示一些提示文字 例如搜索人员 提示人员所属部门  可以使用该属性
    // function
    searchResTip: function (data) {
        if (data.hasOwnProperty('roleId')) {
            return '角色：【' + data.roleId + '】'
        } else if (data.hasOwnProperty('postId')) {
            return '岗位：【' + data.postId + '】'
        }
        else if (data.hasOwnProperty('directorId')) {
            return '主管：【' + data.directorName + '】'
        }
        else if (data.hasOwnProperty('formId')) {
            return '组件：【' + data.label + '】'
        }
        return '部门：【' + data.deptId + '】' + data.deptName
    },
    // 判断该节点是否可选 例如同时选择部门和部门下的人
    disabled: function (data, node) {
        return false
    },
    // 动态请求后台拿到节点数据 返回一个promise
    onload: loadDepData,
    // 搜索节点方法
    onsearch: function (searchString, resolve, reject) {
        if (this.tabKey == 'role') {
            //角色
            resolve(GET_ROLES({ pageNum: 1, pageSize: 50, name: searchString }))
        } else if (this.tabKey == 'position') {
            //岗位
            resolve(GET_POSTS({ pageNum: 1, pageSize: 50, name: searchString }))
        } else  if(this.tabKey == 'dep'){
            //部门
            resolve(GET_DEPT_TREE({ pageNum: 1, pageSize: 50, name: searchString }))
        }else{
            
                GET_DEPT_TREE({ name: searchString }).then(deptData => {
                    return GET_PAGE_EMPLOYEE({ pageNum: 1, pageSize: 100, ignore_datascope: 0, keyword: searchString }).then(userData => {
                        resolve(deptData.concat(userData))
                    })
                })
                //resolve( GET_PAGE_EMPLOYEE({pageNum: 1, pageSize: 100, keyword: searchString}) )
            
        }
    }
}

export const DEP_CONFIG = Object.assign({}, defaultOption)
export const DIRECTOR_CONFIG = Object.assign({}, defaultOption, { tabKey: 'director', tabName: '主管', onload: loadDirectorData, disabled: (data, node) => !data.hasOwnProperty('directorId') })
export const FORM_CONFIG = Object.assign({}, defaultOption, { tabKey: 'form', tabName: '表单', onload: loadFormData, disabled: (data, node) => !data.hasOwnProperty('formId') })
export const ROLE_CONFIG = Object.assign({}, defaultOption, { tabKey: 'role', tabName: '角色', onload: loadRoleData, disabled: (data, node) => !data.hasOwnProperty('roleId') })
export const USER_CONFIG = Object.assign({}, defaultOption, { tabKey: 'user', tabName: '指定人员', onload: loadUserData, disabled: (data, node) =>   !data.hasOwnProperty('userId')})
// export const USER_CONFIG = Object.assign({}, defaultOption, { tabKey: 'user', tabName: '指定人员', onload: loadUserData, disabled: (data, node) => {
//     if( node.childNodes.length ){
//         return false
//     }
//   return  !data.hasOwnProperty('userId')
// }})
const DEP_USER_CONFIG = Object.assign({}, defaultOption, { tabKey: 'dep&user', tabName: '部门和人员', onload: loadUserData, disabled: (data, node) => !data.hasOwnProperty('userId') && !data.hasOwnProperty('deptId') })
const POSITION_CONFIG = Object.assign({}, defaultOption, { tabKey: 'position', tabName: '岗位', onload: loadPostData, disabled: (data, node) => !data.hasOwnProperty('postId') })
export const CONFIG_LIST = [DEP_CONFIG, ROLE_CONFIG, USER_CONFIG, DEP_USER_CONFIG, POSITION_CONFIG, DIRECTOR_CONFIG, FORM_CONFIG]
