import request from '@/utils/request'
import { ReFormatQueryStr } from '@/utils/formatquerystr'


//表单信息
export function FormxShowForminfo(mid) {

    return request({
        url: '/form/data/forminfo/' + mid,
        method: 'get',
    })

}

export function FormxShowList(mid, query) {

    var data = {
        mid: mid,
        page: query.pageNum,
        psize: query.pageSize,
        extaction: JSON.stringify(query.extaction),
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)

    return request({

        url: '/form/data/list/' + mid,
        method: 'post',
        data: data
    })

}

//查询一条数据
export function FormxShowGet(mid, id) {

    return request({
        url: '/form/data/get/' + mid + "?id=" + id,
        method: 'get',
    })

}

//保存一条数据
export function FormxShowSavedata(mid, id, data) {
    var url = ""
    if (id == null) {
        url = "/form/data/add/" + mid
    } else {
        url = "/form/data/edit/" + mid + "?dataid=" + id
    }
    data["mid"] = mid
    data["dataid"] = id

    return request({
        url: url,
        method: 'post',
        data: data
    })

}

//删除数据
export function FormxShowDel(mid, ids) {
    var data = {
        mid,
        ids
    }

    return request({
        url: "/form/data/del/" + mid,
        method: 'post',
        data: data
    })

}
//导入数据
export function FormDataImport(mid, rows, opts) {
    var data = {
        mid,
        options: JSON.stringify(opts),
        rows: JSON.stringify(rows)
    }

    return request({
        url: "/form/data/import/" + mid,
        method: 'post',
        data: data
    })

}

//获取下载连接
export function FormDataDownloadUrl(mid, query, fieldlist) {
    var data = {
        page: query.pageNum,
        psize: query.pageSize,
        qstr: {},
        fieldlist: Array.isArray(fieldlist) ? fieldlist.join("|") : fieldlist
    }

    var tjson = ReFormatQueryStr(query)
    data.qstr = JSON.stringify(tjson)

    var parata = []
    for (let i in data) {
        parata.push(i + "=" + data[i])
    }


    return `/form/data/export/${mid}?` + parata.join("&");


}
//操作记录
export function FormxOprloglist(mid, query, logtype) {
    console.log(mid, query)
    var data = {
        page: query.pageNum,
        psize: query.pageSize,
        logtype: logtype,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)
    return request({
        url: '/form/formdata/oprloglist/' + mid,
        method: 'post',
        data: data
    })

}

//筛选保存模板
export function FormxSavedataFilterTpl(data) {

    return request({
        url: '/form/model/savedatafiltertpl',
        method: 'post',
        data: data
    })

}
//获取保存模板
export function FormxGetdataFilterTpl(query) {
    var data = {
        page: query.page,
        psize: query.psize,
        qstr: {}
    }

    var tjson = ReFormatQueryStr(query)

    data.qstr = JSON.stringify(tjson)
    return request({
        url: '/form/model/datafiltertpllist',
        method: 'post',
        data: data
    })

}

// 
export function sisetuUpload(data) {


    return request({
        url: "/form/sisetu/upload",
        method: 'post',
        data: data
    })

}

export function sisetuDel(data) {


    return request({
        url: "/form/sisetu/del",
        method: 'post',
        data: data
    })

}


export function sisetuGet(data) {

    return request({
        url: "/form/sisetu/get",
        method: 'get'
    })

}
export function sisetuSubtotal() {

    return request({
        url: "/form/sisetu/subtotal",
        method: 'get'
    })

}
export function parseFormDataRowToOption(row, setstr) {
    //setstr 例如 subform39415.[v_E]+分+-+subform39415.[input29579]
    let tar = setstr.split("+")
    let f1 = ""//主要循环字段
    let f2 = ""

    for (let ik in tar) {
        let v = tar[ik]
        if (v.indexOf(".") > -1) {
            f1 = v.split(".")[0]
            f2 = v.split(".")[1]
            break
        }
    }
    let tarlistdata = row[f1]
    if (typeof tarlistdata == "string") {
        tarlistdata = JSON.parse(tarlistdata)
    }

    //console.log("tarlistdata", tarlistdata);

    let rs = []

    //如果是列表
    if (Array.isArray(tarlistdata)) {
        for (let ik = 0; ik < tarlistdata.length; ik++) {
            let onedata = tarlistdata[ik]
          //  console.log("onedata", onedata);

            let oneitemstr = ""
            //console.log("tar", tar)

            for (let ir = 0; ir < tar.length; ir++) {
                let v = tar[ir]


                if (v.indexOf(".") > -1) {

                    let tf = v.split(".")[1]

                    tf = tf.replaceAll("[", "")
                    tf = tf.replaceAll("]", "")

                    oneitemstr += onedata[tf]


                } else {
                    oneitemstr += v
                }
            }
            rs.push(oneitemstr)

        }
    }


    return rs;

};
export function yhpcechartstable(ny) {

    return request({
        url: "/form/yhpc/echarts?ny="+ny,
        method: 'get'
    })

}

export function yhhbechartstable(ny,ty) {

    return request({
        url: "/form/yhhb/echarts?ny="+ny+"&ty="+ty,
        method: 'get'
    })

}

export function pxhyechartstable(ny,ty) {

    return request({
        url: "/form/pxhy/echarts?ny="+ny ,
        method: 'get'
    })

}
export function zxjcdownload(data) {

    return request({
        url: "/form/zxjc/download" ,
        method: 'post',
        data:data
    })

}